import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../home/home.css"
import axios from "axios"
import { Stack } from "@mui/material"
import { Autocomplete, Box, Button, Checkbox, Collapse, Divider, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from "@mui/material"
import { Add, Remove } from "@mui/icons-material"
import MUIDataTable from "mui-datatables"
import { Avatar } from "@material-ui/core"
import AppContext from "../../AppContext"
import { FormGroup, FormLabel, FormControlLabel, Grid } from "@mui/material"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"

import Menu from "@mui/material/Menu"
import ModelList from "../model/ModelList"
import Makes from "../productMakes/Makes"
import Category from "../category/Category"
import Navbar from "../navbar/Navbar"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"

function ManageModels() {
  //start search filter
  //for make filter
  const [filterText, setFilterText] = useState("")
  const [modelName, setModelName] = useState("")
  const [makesOptions, setMakesOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])

  const [selectedCategoryValue, setSelectedCategoryValue] = useState()
  const [selectedMakeValue, setSelectedMakeValue] = useState()
  const [refreshNeeded, setRefreshNeeded] = useState(false)
  const [open,setOpen] = useState(false)
  const [severity,setSeverity] = useState("")
  const [rows,setRows] = useState()
  const [message,setMessage] = useState("")
  
  function checkIfThere(str1, str2, listOfLists) {
    return listOfLists.some(item => item.modelName === str1 && item.makeName === str2);
  }

  function arrayToObject(arr) {
    return {
      id: arr[0],
      modelName: arr[1],
      categoryName: arr[2],
      makeName: arr[3]
    }
  }
  //call api to get the make list on data load
  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/models/getModelMakesCategory`)

    setRows(result.data.map(arrayToObject))
  }, [])

  useEffect(async () => {
    await axios(`${AppContext.apiUrl}/api/makes`).then(result => {
      const makes = result.data.map(cat => ({ value: cat.id, label: cat.name }))
      //console.log("category " + category)
      setMakesOptions(makes)
    })

    let cresult = await axios(`${AppContext.apiUrl}/api/category`)

    const category = cresult.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setCategoryOptions(category)
  }, [])
  const [filterValues, setFilterValues] = React.useState({})

  useEffect(() => {
    //   products.filter((product) =>
    //   product.category.toLowerCase().includes(filter.toLowerCase())
    // )
    data.filter(ads => ads.ca)
  }, [filterValues])
  //end

  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = JSON.parse(localStorage.getItem("token"))

  let file = false
  let urls = []

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  const id = state.user.user.id
  const userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const searchKeyword = e => {
    setKeyword(e.target.value)
  }

  function transformArray(arr) {
    return arr.map(item => (typeof item === "object" && item !== null && "name" in item ? item.name : item))
  }
  // useEffect(async () => {
  //   //check if the user logins, or navigate to home /
  //   const result = await axios.get(`${AppContext.apiUrl}/api/ads`)

  //   const origData = result.data
  //   const convertData = origData.map(e => Object.values(e))
  //   const convertData1 = convertData.map(e => transformArray(e).slice(0, 11))
  //   setData(convertData1)
  // }, [])

  const search = e => {
    e.preventDefault()

    // axios
    //   .get(`http://localhost:8081/api/ads/search/${keyword}`)
    //   .then(response => {
    //     setData(response.data)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
  }

  const logout = e => {
    const action = removeUser()
    dispatch(action)
    navigate("/")
  }

  {
    /* test start */
  }

  const columns = [
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRender: v => {
          //return <Avatar variant="rounded" src="https://res.cloudinary.com/elmelm/image/upload/v1706799276/adsimage/x0vbqpysjrdnpwwxok2w.jpg"></Avatar>
          return <Avatar variant="rounded" src={data[v - 1][7]}></Avatar>
        },
        filter: false
      }
    },
    {
      name: "listing#",
      label: "Listing#",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "make",
      label: "Make",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[3]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Makes</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={selected => selected.join(", ")}
                  onChange={event => {
                    filterList[index] = event.target.value
                    onChange(filterList[index], index, column)
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        }
      }
    },

    {
      name: "model",
      label: "Model",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[4]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Model</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={selected => selected.join(", ")}
                  onChange={event => {
                    filterList[index] = event.target.value
                    onChange(filterList[index], index, column)
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[5]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Category</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={selected => selected.join(", ")}
                  onChange={event => {
                    filterList[index] = event.target.value
                    onChange(filterList[index], index, column)
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "waferSize",
      label: "WaferSize",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "image1",
      label: "Image1",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[8]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Company</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={selected => selected.join(", ")}
                  onChange={event => {
                    filterList[index] = event.target.value
                    onChange(filterList[index], index, column)
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "contact",
      label: "Contact",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[9]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Contact</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={selected => selected.join(", ")}
                  onChange={event => {
                    filterList[index] = event.target.value
                    onChange(filterList[index], index, column)
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "Date added",
      label: "Date added",
      options: {
        filter: false,
        sort: false,
        display: id ? true : false
      }
    }
  ]

  const data1 = [
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"]
  ]

  const options = {
    filterType: "checkbox",
    filter: true,
    responsive: "scroll",
    download: false,
    sort: false,
    selectableRows: false,
    print: false,
    viewColumns: false,
    searchOpen: false,
    searchText: keyword,
    search: false,
    rowHover: true,
    responsive: "vertical",
    onRowClick: (rowData, rowMeta) => {
      console.log(rowData)
      console.log(rowMeta)
      console.log(rowMeta.dataIndex)

      console.log(data[rowMeta.dataIndex][0])
      let _id = data[rowMeta.dataIndex][0]
      window.open(`/ads/${_id}`)
      // this.props.history.push({
      //   pathname: `/ads/${_id}`,
      //   state: { issue: data[rowMeta.rowIndex] }
      // })
    }
  }

  {
    /* test end */
  }

  const [isFormVisible, setFormVisible] = useState(false)

  const handleAddRecord = () => {
    setFormVisible(true)
  }

  const handleCloseForm = () => {
    setFormVisible(false)
    setModelName("")
    setSelectedCategoryValue("")
    setSelectedMakeValue("")
  }

  const handleNewSubmit = async e => {
    e.preventDefault()
    if(checkIfThere(modelName,selectedMakeValue.label,rows)){
      setOpen(true)
      setSeverity("error")
      setMessage("The same make,model already exist")
      return
    }
    //so the list will be freshed when new added
    setRefreshNeeded(false)
    let randomModelId = Math.abs(randomInt())
    axios
      .post(`${AppContext.apiUrl}/api/models`, { id: randomModelId, name: modelName, makes: { id: selectedMakeValue ? selectedMakeValue.value : "" } }, config)
      .then(
        //create category as well to associate with the new model
        axios
          .post(`${AppContext.apiUrl}/api/category/${selectedMakeValue.value}/${randomModelId}`, { name: selectedCategoryValue.label, makes: { id: selectedMakeValue ? selectedMakeValue.value : "" }, model: { id: randomModelId } }, config)
          .then(()=>
            {
              handleCloseForm()
              setOpen(true)
              setFormVisible(false)
              setMessage("Model added successfully, just click on the 3 dots right to the Model column and filter, or just to go and create listing and search the make, model")
              setSeverity("success")
              setRefreshNeeded(true)
            // setTimeout(function () {
            //   // Something you want delayed.
            //   setRefreshNeeded(true)
              
            // }, 3000)
          }
          )
          .catch(e => {
            setOpen(true)
              setFormVisible(false)
              setMessage("something happend when editing model")
              setSeverity("error")
            console.log("something happend when editing model")
          })
      )
      .catch(e => {
        console.log("something happend when editing model")
      })
  }

  return (
    <div>
      <Snackbar
  open={open}
  autoHideDuration={6000} // Adjust duration as needed
  onClose={() => setOpen(false)}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Optional: Adjust position
>
  <Alert
    onClose={() => setOpen(false)}
    severity={severity}
    sx={{ width: '100%' }}
  >
    {message}
  </Alert>
</Snackbar>
  

      <Navbar />
      {/* <div className="search">
        <form className="example">
          <input type="text" placeholder="..something in mind? " onChange={searchKeyword} />
          <button>
            <i className="bi bi-search" onClick={search}></i>
          </button>
        </form>
      </div> */}
      <div className="category-nav">
        {/* <ul>
          <button className="btn btn-secondary home-button">
            <i onClick={all}>All</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={buying}>buying</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={selling}>selling</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={car}>front end equipments</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={realEstate}>backend equipments</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={devices}>Devices</i>
          </button>
        </ul> */}
      </div>

      {/* <LeftSidebarFilter />
        <ProductTable /> */}
      {/* <Box sx={{ bgcolor: "#F5F5F5", width: "300px", p: "24px", marginLeft: "20px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box fontSize="h6.fontSize" fontWeight="bold">
              Filters
            </Box>
            <Button variant="text" color="primary" onClick={handleResetAll}>
              Reset All
            </Button>
          </Box>
          <List>
            {filters.map((filter, index) => (
              <React.Fragment key={filter.label}>
                <ListItem button onClick={() => handleToggleFilter(filter.label)}>
                  <ListItemText primary={filter.label} />
                  {openFilters[filter.label] ? <Remove sx={{ color: filter.required ? "red" : undefined }} /> : <Add sx={{ color: filter.required ? "red" : undefined }} />}
                </ListItem>
                <Collapse in={openFilters[filter.label]} timeout="auto" unmountOnExit>
                  {filter.type === "autocomplete" && (
                    <ListItem sx={{ pl: 4 }}>
                      <Autocomplete
                        options={filter.options}
                        renderInput={params => <TextField {...params} label={filter.label} fullWidth />}
                        onChange={(event, value) => handleAutocompleteChange(event, value, filter.label)}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            border: "none"
                          }
                        }}
                      />
                    </ListItem>
                  )}
                  {filter.type === "select" && (
                    <ListItem sx={{ pl: 4 }}>
                      <FormControl fullWidth>
                        <InputLabel>{filter.label}</InputLabel>
                        <Select label={filter.label} name={filter.label} value={filterValues[filter.label] || ""} onChange={handleFilterChange}>
                          {filter.options.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ListItem>
                  )}
                  {filter.type === "checkbox" && (
                    <List component="div" disablePadding>
                      {filter.options.map(option => (
                        <ListItem key={option} button sx={{ pl: 4 }}>
                          <Checkbox
                            checked={(Array.isArray(filterValues[filter.label]) && filterValues[filter.label].includes(option)) || false}
                            onChange={handleCheckboxChange}
                            name={filter.label}
                            value={option}
                            sx={{
                              "&.Mui-checked": {
                                color: "red"
                              }
                            }}
                          />
                          <ListItemText primary={option} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Collapse>
                {index < filters.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Box> */}

      <div className="all-ads">
        <p>Manage models, make -- category</p>
        <div style={{ marginBottom: "20px" }}>
          <button onClick={handleAddRecord}>Add Record</button>

          {isFormVisible && (
            <div>
              <form style={{ border: "1px solid #ddd", marginBottom: "40px", display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>
                  <label>
                    <span style={{ color: "red" }}>*</span>Model Name:
                  </label>
                  <input type="text" autoFocus value={modelName} onChange={e => setModelName(e.target.value)} name="modelName" id="modelName_id" />
                </div>
                <div>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div style={{ marginRight: "10px", width: "250px" }}>
                  {" "}
                  <Makes options={makesOptions} selectedValue={selectedMakeValue} onSelectChange={setSelectedMakeValue} />
                </div>
                <div>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div style={{ marginRight: "20px", width: "250px" }}>
                  {" "}
                  <Category options={categoryOptions} selectedValue={selectedCategoryValue} onSelectChange={setSelectedCategoryValue} />
                </div>
                {/* Add other form fields as needed */}
                <button style={{ marginRight: "10px" }} type="button" onClick={handleNewSubmit}>
                  Add
                </button>
                <button type="button" onClick={handleCloseForm}>
                  Close
                </button>
              </form>
            </div>
          )}
        </div>
        {/* test start */}
        <p>Models list: </p>
        <ModelList refreshNeeded={refreshNeeded} />
        {/* <MUIDataTable title={""} data={data} columns={columns} options={options} /> */}

        {/* test end */}
      </div>
    </div>
  )
}

export default ManageModels
