import React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';
// import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { encodeNumberTo10CharString } from "../../NumberCodec"
import { useState } from 'react';


const ContactListWithGlobalSearch = ({ searchTerm, onSearchChange, showSearchBox }) => {
  // Sample data
  const [rows, setRows] = React.useState([])
  const navigate = useNavigate()
  const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))
  
  // const hasNonSalesRepRole = roles.some(role => {
  //   return typeof role.name === 'string' && role.name !== "sales_rep";
  // });
  const isAdmin = () => {
    return roles.name.includes("user");
  };
  //start config
  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })
  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  //end config

  //start set rows
  function convertAllDatesToYyyyMmDd(allData) {
    return allData.map(item => {
        return {
            ...item,
            createdAt: new Date(item.createdAt).toISOString().split('T')[0]
        };
    });
}

  
  //const pipelines = [{ id: "122222", status: "10%", date_created: "2024-02-02" }]
  //call api to get the pipelines list on data load
  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/admin/contacts`, config)
    // const converted = convertAllDatesToYyyyMmDd(result.data)
    setRows(result.data.map(arrayToObject)
    .sort(((a, b) => new Date(b.createdAt) - new Date(a.createdAt))))
    // const filteredData = converted
    //   .filter(item => item.status !== '0% - no Deal')
    //   .filter(item => item.status !== '100% - Deal')
    //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    //   const filteredStatusData = converted
    //   .filter(item => item.status === '0% - no Deal')
    //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    //   const filteredStatus100Data = converted
    //   .filter(item => item.status === '100% - Deal')
    //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // if(props.status==="0"){
    //   setRows(filteredStatusData)
    // }else if(props.status==="100"){
    //   setRows(filteredStatus100Data)
    // }else{
    //   setRows(filteredData)
    // }
  }, [searchTerm])

  React.useEffect(async () => {
    
      let result = await axios.get(`${AppContext.apiUrl}/api/admin/contacts`, config)
      // const converted = convertAllDatesToYyyyMmDd(result.data)
      setRows(result.data.map(arrayToObject)
      .sort(((a, b) => new Date(b.createdAt) - new Date(a.createdAt))))
      // const filteredData = converted
      //   .filter(item => item.status !== '0% - no Deal')
      //   .filter(item => item.status !== '100% - Deal')
      //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      //   const filteredStatusData = converted
      //   .filter(item => item.status === '0% - no Deal')
      //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      //   const filteredStatus100Data = converted
      //   .filter(item => item.status === '100% - Deal')
      //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
      // if(props.status==="0"){
      //   setRows(filteredStatusData)
      // }else if(props.status==="100"){
      //   setRows(filteredStatus100Data)
      // }else{
      //   setRows(filteredData)
      // }
    }, [])
  
  //end set rows

  // Column definitions
  const columns = [
    { field: "id", headerName: "Id", width: 70},
    { field: "contactName", headerName: "Contact Name", width: 170},
    { field: "email", headerName: "Email", width: 170},
    { field: "email2", headerName: "Email2", width: 170},
    { field: "email3", headerName: "Email3", width: 170},
    { field: "note", headerName: "Note", width: 1070},
    
    
  ]

  // Filtering logic based on the passed searchTerm prop
  const filteredRows = rows?rows.filter((row) =>
    Object.values(row).some((value) =>
      value?value.toString().toLowerCase().includes(searchTerm.toLowerCase()):false
    )
  ):"";

  const goToDetails = rowSelected =>{
    //alert(rowSelected.id)
    localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
  
    if(isAdmin()){
      window.open("/admin/contact/"+encodeNumberTo10CharString(rowSelected.id),"_blank")
    }
  }
  function trimString(input, length) {
    if (input === null) {
      return "";
    }
    return input.slice(0, length);
  }
  function arrayToObject(arr) {
    return {
      id: arr.id,
      contactName:isAdmin()?(arr.firstName + " " + arr.lastName):trimString(arr.firstName,5),
      note:arr.note,
      email:arr.contactEmail,
      email2:arr.contactEmail2,
      email3:arr.contactEmail3
    }
  }
  return (
    <div style={{ height: 400, width: '100%' }}>
      {/* Conditionally show the search box */}
      {showSearchBox && (
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          style={{ marginBottom: '20px' }}
          value={searchTerm} // Controlled by the prop
          onChange={(e) => onSearchChange(e.target.value)} // Update search term via callback
        />
      )}

      {/* MUI DataGrid */}
      <DataGrid
        rows={filteredRows} // Display filtered rows
        columns={columns}
        pageSize={5}
        onRowClick={goToDetails}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
};

export default ContactListWithGlobalSearch;
