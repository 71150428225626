import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const roles = ["Market", "Finance", "Development"]
const randomRole = () => {
  return randomArrayItem(roles)
}

// const initialRows = [
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 25,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 36,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 19,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 28,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 23,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   }
// ]

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props

  const handleClick = () => {
    const id = Math.abs(randomInt())
    setRows(oldRows => [...oldRows, { id, name: "", isNew: true }])
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" }
    }))
  }

  return (
    // <GridToolbarContainer>
    //   <Button color="primary" onClick={handleClick}>
    //     Add Lead record
    //   </Button>
    // </GridToolbarContainer>
    <></>
  )
}

export default function ManageEmailDraftList() {
  const [rows, setRows] = React.useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})
  const [modelList, setModelList] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)
  const navigate = useNavigate()

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  React.useEffect(async () => {
    const uniqueNamesMap = new Map()

    let result = await axios.get(`${AppContext.apiUrl}/api/emaildraft`, config)
    

    // Convert the Map values to an array
    const uniqueNamesAndIds = Array.from(uniqueNamesMap, ([name, id]) => ({ id, name }))

    setRows(result.data)
  }, [refresh])
  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }
  //call api to get the make list on data load
  React.useEffect(async () => {
    const uniqueNamesMap = new Map()

    let result = await axios.get(`${AppContext.apiUrl}/api/emaildraft`,config)
    

    setRows(result.data)
  }, [])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }
  const goToDetails = rowSelected =>{
    //alert(rowSelected.id)
    localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
  
    navigate(`/admin/emailDraftDetail/${rowSelected.id}`)
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => () => {
    setRows(rows.filter(row => row.id !== id))
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    console.log(newRow.id, newRow.firstName, updatedRow.isNew)

    if (newRow.isNew) {
      axios
        .post(`${AppContext.apiUrl}/api/leads`, { id: newRow.id, firstName: newRow.firstName }, config)
        .then(
          setTimeout(function () {
            // Something you want delayed.
            setRefresh(!refresh)
          }, 3000)
        )
        .catch(e => {
          console.log("something happend when editing makes")
        })
    } else {
      axios
        .put(`${AppContext.apiUrl}/api/leads/${newRow.id}`, { id: newRow.id, firstName: newRow.firstName }, config)
        .then()
        .catch(e => {
          console.log("something happend when editing makes")
        })
    }
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    { field: "id", headerName: "id", width: 80, editable: false },
    { field: "fromEmail", headerName: "From Email", width: 200, editable: true },
    { field: "toEmail", headerName: "To Email", width: 200, editable: true },
    { field: "subject", headerName: "subject", width: 280, editable: true },
    { field: "body", headerName: "body", width: 480, editable: true },
    { field: "contactName", headerName: "Contact Name", width: 180, editable: true },
    { field: "listUrl", headerName: "listUrl", width: 180, editable: true },
    { field: "assigneeId", headerName: "assignee Id", width: 180, editable: true },
    { field: "assigneeName", headerName: "assigneeName", width: 180, editable: true },
    
    { field: "createdAt", headerName: "creation time", width: 180, editable: true },
    // {
    //   field: "model",
    //   headerName: "Model",
    //   width: 420,
    //   editable: true,
    //   type: "singleSelect",
    //   renderCell: params => (
    //     <select
    //       style={{ width: "400px" }}
    //       //value={params.value[0] ? params.value[0].id : ""} // Assuming params.value contains the selected model
    //       value={{ id: params.row.model && params.row.model.length > 0 ? params.row.model[0].id : "", name: params.row.model && params.row.model.length > 0 ? params.row.model[0].name : "" }} // Assuming params.value contains the selected model
    //       onChange={e => {
    //         // Handle the model change here
    //         const newValue = e.target.value
    //         console.log("------params---" + params)
    //         // You may want to call an update function to update your data
    //         // For example: updateModel(params.id, newValue)
    //       }}
    //     >
    //       {params.value &&
    //         params.value.map(modelOption => (
    //           <option key={modelOption ? modelOption.id : ""} id={modelOption ? modelOption.id : ""} value={modelOption ? modelOption.name : ""}>
    //             {modelOption ? modelOption.name : ""}
    //           </option>
    //         ))}
    //     </select>
    //   )
    // },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />
          ]
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />
          //<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ]
      }
    }
  ]

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="cell"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
        onRowClick={goToDetails}

      />
    </Box>
  )
}
