import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import CompanyList from "../company/CompanyList"
import Makes from "../productMakes/Makes"
import Models from "../model/Models"
import CompanyContactList from "../companyContact/CompanyContactList"
import Select from "react-select"
import countryList from "react-select-country-list"
import AppContext from "../../AppContext"
import Category from "../category/Category"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import FileUpload from "../FileUpload"
import InternalFileUpload from "../InternalFileUpload"
import ExternalFileUpload from "../ExternalFileUpload"
import { encodeNumberTo10CharString, decodeStringToNumber } from "../../NumberCodec"
import { useScrollTrigger } from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import { Alert } from "@mui/material"
import { Button } from "react-bootstrap"
import EmailPrompt from "./EmailPrompt"

function EmailDraftDetails(props) {
  const params = useParams() //params.adsId
  const [data, setData] = useState()
  const [listUrl, setListUrl] = useState()
  const [fromEmail, setFromEmail] = useState()
  const [toEmail, setToEmail] = useState(0)
  const [contactName, setContactName] = useState()
  const [body, setBody] = useState()
  const [subject, setSubject] = useState()
  const [model,setModel] = useState()
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState()
  const [severity, setSeverity] = useState()
  const [reasonLost, setReasonLost] = useState()
  const [selectedReasonValue, setSelectedReasonValue] = useState()
  const [assigneeId, setAssigneeId] = useState()
  const [assigneeName, setAssigneeName] = useState()
  const [salesRepOption, setSalesRepOption] = useState()
  const [selectedSalesRepOptionValue, setSelectedSalesRepOptionValue] = useState()
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))
  const [contactIds,setContactIds] = useState([])
  const [companyIds,setCompanyIds] = useState([])
  const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [selectedStatusValue,setSelectedStatusValue] = useState()
  const [prompt,setPrompt] = useState(`
  Write a professional email in 100 words introducing a new ad listing. The email should include:
  - The recipient's name: ${contactName}
  - The equipment model: ${model}
  - A URL to the listing: ${listUrl}
  - A reference to the recipient's previous interest in the ${model}.
  - The sender's name: ${assigneeName}
  - The sender's position: Sales Manager
  - The sender's contact information: ${fromEmail}.
  
  Make the tone polite, engaging, and concise. Use proper formatting for a professional email.
    `)

  // const hasNonSalesRepRole = roles.some(role => {
  //   return typeof role.name === 'string' && role.name !== "sales_rep";
  // });
  const isAdmin = () => {
    return roles.name.includes("user");
  };

  //save price on the page
  // Initialize state for all items in the matchList
  // Initialize state for all items in the matchList
  const [priceList, setPriceList] = useState([])

  function formatCurrency(e) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(e)
  }

  const resetStyle = {
    backgroundColor: "transparent", // Revert any background color changes
    border: "2px inset", // Default style for many browsers, but not all
    fontFamily: "initial", // Reverts to the default font
    fontSize: "medium", // Default size
    color: "initial", // Reverts to the default text color
    padding: "initial", // Removes any padding
    margin: "initial" // Removes any margin
  }

  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  const statusOption = [
    { value: "draft", label: "draft" },
    { value: "sent", label: "sent" },
    { value: "deleted", label: "deleted" }
  ]
  function calculateSums(dataTemp) {
    let sumObject1 = 0
    let sumObject2 = 0

    dataTemp &&
      dataTemp.forEach(item => {
        if (item.wantedAds && item.wantedAds.price) {
          sumObject1 += item.wantedAds.price
        }
        if (item.forSaleAds && item.forSaleAds.price) {
          sumObject2 += item.forSaleAds.price
        }
      })

    return sumObject1 - sumObject2
  }

  const reasonOption = [
    { value: "Price not Matched", label: "Price not Matched" },
    { value: "Out-bided", label: "Out-bided" },
    { value: "Technical Not Matched", label: "Technical Not Matched" },
    { value: "Project Discontinued", label: "Project Discontinued" },
    { value: "Duplicate Pipeline", label: "Duplicate Pipeline" }
  ]
  // Dynamically generate the email prompt
  

  useEffect(async () => {
    axios.get(`${AppContext.apiUrl}/api/user`, config).then(resp => {
      // Filter for users who have the "sales_rep" role
      const salesReps = resp.data

      // Map the sales_rep role's id and name to { id, label } for selection options
      const salesRepOptions = salesReps.map(user => {
        const salesRepRole = user.roles.find(role => role.name === "sales_rep")
        return {
          value: user.id, // The role's ID
          label: user.name // The user's name
        }
      })
      setSalesRepOption(salesRepOptions)
    })

    //get contact ids and company ids by sales id
    axios.get(`${AppContext.apiUrl}/api/contacts/sales/${assigneeId}`, config).then(resp => {
      //
      const uniqueContactIds = [];
const uniqueCompanyIds = [];

// Loop through the data to get unique values
resp.data.forEach(item => {
    const contactId = item[0];
    const companyId = item[1];

    if (!uniqueContactIds.includes(contactId)) {
        uniqueContactIds.push(contactId);
    }
    if (!uniqueCompanyIds.includes(companyId)) {
        uniqueCompanyIds.push(companyId);
    }


}
);
      setContactIds(uniqueCompanyIds)
      setCompanyIds(uniqueCompanyIds)
    })


    const result = await axios.get(`${AppContext.apiUrl}/api/emaildraft/${params.emailDraftId}`, config).then(res => {
      setData(res.data)

      setListUrl(res.data.listUrl)
      setFromEmail(res.data.fromEmail)
      setToEmail(res.data.toEmail)
      setSubject(res.data.subject)
      setContactName(res.data.contactName)
      setModel(res.data.model)
      setAssigneeName(res.data.assigneeName)
      setPrompt(res.data.prompt)

      setSelectedStatusValue({ value: res.data.status, label: res.data.status })
      setBody(res.data.body)
      
      setSelectedSalesRepOptionValue({ value: res.data.assigneeId, label: res.data.assigneeName })
    })

    // setCategory(result.data.category)
    // setCategoryName(result.data.category.name)
  }, [])

  const goToListingDetails = id => {
    //alert(rowSelected.id)
    //e.preventDefault()
    window.open(`/editCard/${id}`)
    //navigate(`/editCard/${encodeNumberTo10CharString(id)}`)
  }

  const goToContactDetails = id => {
    //alert(rowSelected.id)
    //e.preventDefault()

    //navigate(`/admin/contact/${encodeNumberTo10CharString(id)}`)
    window.open(`/admin/contact/${encodeNumberTo10CharString(id)}`)
  }

  const goToCompanyDetails = id => {
    //alert(rowSelected.id)
    //e.preventDefault()

    //navigate(`/admin/company/${id}`)
    window.open(`/admin/company/${id}`)
  }

  const changeHandler = value => {
    setValue(value)
  }
  const token = JSON.parse(localStorage.getItem("token"))

  let file = false
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("")

  const handleSelectCategoryChange = value => {
    setSelectedCategoryValue(value)
    console.log("category is ", value)
  }

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  
  useEffect(()=>{
    setPrompt(`
    Write a business professional email in 300 words introducing a new for-sale listing,  The email should include:   
   -  A short greeting, if it’s in the holiday season, then say something about the current standard American holiday, and use a specific holiday name. 
     For example: I hope you are 
    - The recipient's name: ${contactName}
    
    - The equipment model: ${model}
    - A URL to the listing: ${listUrl}, make it hyperlink with link name of the ${model}
    - A reference to the recipient's previous interest in the ${model}.
    - The sender's first name: null 
       If the sender’s name is empty or shows general/general contact/Not Available, then leave the name blank and just say Hi or Hello
    - The sender's contact information: null@resochi.com.
     - Don’t make any comment on the tool’s condition, unless it shows in our system or website  
    -  Ramodmly ( don’t need to always) remind them we are buying and selling a lot of different tools, and parts, so let us know if there are any new situations.
     -  Ending you can write something like Hope you have a great weekend, great holiday if it’s in on Friday, Saturday, or Sunday, or about to get into a holiday season.    
Also, please create only body, don't attach the signature


General Email Rule

Tone: 

Make the tone polite, engaging, and concise. Use proper formatting for a professional email.
    If we send an email to the recipient the first time, then be more polite, and if it’s a repeat customer that we email pretty often, then be more casual. 
    Also, if the recipient company type is an end-user, then be more polite, Refurbisher, medium, and Broker and Inventory Dealer can be less formal. 


Wording:
Business professional or Business Casual email in 300 words introducing a new ad listing

All the rules are not for you to use exact words, it’s for you to understand the logic. 
No duplicate:
Each time send a different email in both title and content, never send exactly the same email twice. 

   
      `)
  },[model,fromEmail,toEmail,subject,contactName,assigneeName])

  const saveDetails = e => {
    e.preventDefault()
    //call pipeline endpoint to save data
    //need to add internalFiles
    const data = {
      //id:params.pipelineId,
      status: selectedStatusValue ? selectedStatusValue.value : statusOption[0].value,
      assigneeId: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.value : 0,
      assigneeName: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.label : "",
      prompt: prompt,
      fromEmail:fromEmail,
      toEmail:toEmail,
      subject:subject,
      listUrl:listUrl,
      body:body

    }

    axios
      .put(`${AppContext.apiUrl}/api/emaildraft/${params.emailDraftId}`, data, config)
      .then(res => {
        setOpen(true)
        setSeverity("success")
        setMessage("Email draft saved successfully.")
      })
      .catch(e => {
        console.log("something happend when editing makes")
        setOpen(true)
        setSeverity("error")
        setMessage("Something went wrong, please contact the admin for help")
      })
  }

  const sendEmail = e => {
    e.preventDefault()
    //call pipeline endpoint to save data
    
    const data = {
      //id:params.pipelineId,
      
      from:fromEmail,
      to:toEmail,
      subject:subject,
      listUrl:listUrl,
      body:body,
      name:assigneeName,
      title:"Sales Manager",
      phone:"+1-480-942-2817",
      emailDraftId:params.emailDraftId

    }
    

    axios
      .post(`${AppContext.apiUrl}/api/admin/gmailtool/send/`, data, config)
      .then(res => {
        setOpen(true)
        setSeverity("success")
        setMessage("Email sent successfully.")
        setSelectedStatusValue({label:"sent",value:"sent"})
      })
      .catch(e => {
        console.log("something happend when sending email")
        setOpen(true)
        setSeverity("error")
        setMessage("Something went wrong, please contact the admin for help")
      })
  }
  
  const addMoreToPrompt = e => {
    e.preventDefault()
    //call pipeline endpoint to save data
    //need to add internalFiles
    
    const data ={
      message:prompt
    }
    axios
      .get(`${AppContext.apiUrl}/api/admin/gmailtool/latestThreadChecker?orgEmail=resochi.com&customerEmail=${toEmail}`, config)
      .then(res => {
        setOpen(true)
        setSeverity("success")
        setMessage("Email generated successfully.")
        setPrompt(prompt+"More context: latest email body: "+res.data)
      })
      .catch(e => {
        console.log("something happend when editing makes")
        setOpen(true)
        setSeverity("error")
        setMessage("Something went wrong, please contact the admin for help")
      })
  }

  const generateWithPrompt = e => {
    e.preventDefault()
    //call pipeline endpoint to save data
    //need to add internalFiles
    
    const data ={
      message:prompt
    }
    axios
      .post(`${AppContext.apiUrl}/api/chat`, data, config)
      .then(res => {
        setOpen(true)
        setSeverity("success")
        setMessage("Email generated successfully.")
        setBody(res.data.choices[0]?res.data.choices[0].message.content:"")
      })
      .catch(e => {
        console.log("something happend when editing makes")
        setOpen(true)
        setSeverity("error")
        setMessage("Something went wrong, please contact the admin for help")
      })
  }


  // Ensure priceList is correctly initialized when matchList changes
  

  
  

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Navbar />
      {data && (
        <form>
          <div className="ads-from">
            <div style={{ textAlign: "center", fontWeight: "bolder" }}>
              <h3>Email Draft</h3>
            </div>
            <hr />
            <div>
              <label>ListUrl:</label>
            </div>
            <div >{listUrl} </div>
            
           
            
            <div>
              <label>Email Status:</label>
            </div>
            <Select options={statusOption} value={selectedStatusValue} onChange={e => setSelectedStatusValue(e)} />
            {roles &&roles.name && roles.name[0]!="sales_rep" &&(
            <>
            <div>
              <label>Assignee:</label>
            </div>
            <Select options={salesRepOption} value={selectedSalesRepOptionValue} onChange={e => setSelectedSalesRepOptionValue(e)} />
            </>
            )}
            <div>
              <label>From Email:</label>
            </div>
            <input
              type="text"
              style={resetStyle}
              value={fromEmail}
              onChange={evn => {
                setFromEmail(evn.target.value)
              }}
            />
            <div>
              <label>To Email:</label>
            </div>
            <input
              type="text"
              style={resetStyle}
              value={toEmail}
              onChange={evn => {
                setToEmail(evn.target.value)
              }}
            />
            <div>
              <label>Subject:</label>
            </div>
            <input
            readOnly={true}
              type="text"
              style={resetStyle}
              value={subject}
              onChange={evn => {
                setSubject(evn.target.value)
              }}
            />
            
            <div>
              <label> prompt:</label>
            </div>
            
            <textarea rows="10" value={prompt} cols="100"
              type="textarea"
              style={resetStyle}
              onChange={evn => {
                setPrompt(evn.target.value)
              }}
            />
            {/* <Select options={reasonOption} value={selectedReasonValue} onChange={e => setSelectedReasonValue(e)} /> */}
            <div>
            <button type="submit" className="btn btn-primary" onClick={addMoreToPrompt}>
              Add more information to prompt, e.g. last email thread
            </button>
            </div>
            <div>
            <button type="submit" className="btn btn-primary" onClick={generateWithPrompt}>
              Generate
            </button>
            </div>
            <div>
              <label>body:</label>
            </div>
            <textarea rows="10" value={body} cols="100"
              type="textarea"
              style={resetStyle}
              onChange={evn => {
                setBody(evn.target.value)
              }}
            />
            <hr />
            
<div>
            <button type="submit" className="btn btn-primary" onClick={saveDetails}>
              Save
            </button>

            <button type="submit" className="btn btn-primary" style={{marginLeft:"20px"}} disabled={selectedStatusValue?selectedStatusValue.value==="sent":false} onClick={sendEmail}>
              Send
            </button></div>
          </div>
        </form>
      )}
    </div>
  )
}

export default EmailDraftDetails
