import React, { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import axios from "axios"
import { Margin } from "@mui/icons-material"
import AppContext from "../AppContext"
import { useDispatch, useSelector } from "react-redux"
import imageCompression from 'browser-image-compression';

const FileUpload = ({ initialImages, onImagesChange, type }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const acceptedFilesType = type === "internal" 
    ? ".docx,.doc,image/*,audio/*,video/*,.xls"
    : type === "external" 
    ? ".docx,.doc,image/*,.xls"
    : "image/*,video/*";

  const token = JSON.parse(localStorage.getItem("token"));

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: token
    }
  });

  let userToken = state.token;
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  };

  // Function to check file type
  function getFileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'];
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi'];
    
    if (imageExtensions.includes(extension)) return 'image';
    if (videoExtensions.includes(extension)) return 'video';
    return 'other';
  }

  useEffect(() => {
    setUploadedFiles(initialImages?.map(url => ({
      url,
      type: getFileType(url)
    })) || []);
  }, [initialImages]);

  const onDrop = async acceptedFiles => {
    const uploadedUrls = await Promise.all(
      acceptedFiles.map(async file => {
        const fileType = getFileType(file.name);
        if (fileType === 'image') {
          return compressAndUploadImage(file);
        }
        return uploadFile(file);
      })
    );

    const newFiles = uploadedUrls.filter(url => url).map(url => ({
      url,
      type: getFileType(url)
    }));

    setUploadedFiles(prev => [...prev, ...newFiles]);
    onImagesChange([...uploadedFiles, ...newFiles].map(file => file.url));
  };

  // Options for the image compression
  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const compressAndUploadImage = async (file) => {
    try {
      const compressedFile = await imageCompression(file, options);
      return uploadFile(compressedFile);
    } catch (error) {
      console.error('Error during image compression:', error);
      return uploadFile(file);
    }
  };

  const uploadFile = async file => {
    try {
      const data = new FormData();
      data.append("file", file);
      
      if (type === "internal") {
        data.append("upload_preset", "adsimage");
        const res = await axios.post(`${AppContext.apiUrl}/api/internalFile/upload`, data, config);
        if (!res.data.includes("http")) {
          return `${AppContext.apiUrl}/api/file/${res.data}`;
        }
        return res.data;
      } else {
        // For external uploads (Cloudinary)
        data.append("upload_preset", "adsimage");
        const uploadUrl = getFileType(file.name) === 'video' 
          ? "https://api.cloudinary.com/v1_1/dmcaw0vsd/video/upload"
          : "https://api.cloudinary.com/v1_1/dmcaw0vsd/image/upload";
        
        const res = await axios.post(uploadUrl, data);
        return res.data.secure_url;
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      return null;
    }
  };

  const onRemove = (index, e) => {
    e.preventDefault();
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
    onImagesChange(updatedFiles.map(file => file.url));
  };

  const onAddToFirst = (index, event) => {
    event.preventDefault();
    const updatedFiles = [...uploadedFiles];
    const movedFile = updatedFiles.splice(index, 1)[0];
    updatedFiles.unshift(movedFile);
    setUploadedFiles(updatedFiles);
    onImagesChange(updatedFiles.map(file => file.url));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFilesType
  });

  const renderPreview = (file, index) => {
    if (file.type === 'image') {
      return <img src={file.url} alt={`File ${index}`} style={imageStyles} />;
    }
    if (file.type === 'video') {
      return (
        <video 
          controls 
          style={videoStyles}
        >
          <source src={file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return <div style={fileStyles}>{file.url}</div>;
  };

  return (
    <div style={{borderColor: "blue", borderWidth: "0.5px", marginLeft: "10px"}}>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop your files here, or click to select</p>
      </div>

      <div>
        <p>Uploaded {type === "image" ? "Media" : type === "internal" ? "Internal files" : "External files"}</p>
        <ul style={listStyles}>
          {uploadedFiles.map((file, index) => (
            <li key={index} style={liStyles}>
              {renderPreview(file, index)}
              <div style={buttonContainerStyles}>
                <button style={buttonStyles} onClick={e => onRemove(index, e)}>
                  Remove
                </button>
                <button style={buttonStyles} onClick={e => onAddToFirst(index, e)}>
                  Set First
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "1px dashed #cccccc",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "20px"
};

const listStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  gap: "20px",
  padding: "0",
  listStyle: "none"
};

const imageStyles = {
  width: "100%",
  height: "150px",
  objectFit: "cover",
  borderRadius: "4px"
};

const videoStyles = {
  width: "100%",
  maxHeight: "150px",
  borderRadius: "4px"
};

const fileStyles = {
  padding: "10px",
  backgroundColor: "#f5f5f5",
  borderRadius: "4px",
  wordBreak: "break-all"
};

const liStyles = {
  border: "1px solid #cccccc",
  borderRadius: "8px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  gap: "10px"
};

const buttonContainerStyles = {
  display: "flex",
  gap: "10px",
  marginTop: "auto"
};

const buttonStyles = {
  padding: "5px 10px",
  border: "1px solid #cccccc",
  borderRadius: "4px",
  backgroundColor: "#ffffff",
  cursor: "pointer",
  flex: 1,
  transition: "background-color 0.2s",
  ":hover": {
    backgroundColor: "#f0f0f0"
  }
};

export default FileUpload;