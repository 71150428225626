import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"
import Home from "./component/home/Home"
import Login from "./component/login/Login"
import Singup from "./component/singup/Singup"
import EditUserInfo from "./component/editUserInfo/EditUserInfo"
import AddAds from "./component/addAds/AddAds"
import Ads from "./component/ads/Ads"
import EditAds from "./component/editAds/EditAds"
import EditCard from "./component/editCard/EditCard"
import AppContext from "./AppContext"
import ManageMakes from "./component/manage/ManageMakes"
import ManageModels from "./component/manage/ManageModels"
import ManageCategory from "./component/manage/ManageCategory"
import ManageCompany from "./component/manage/ManageCompany"
import ManageContact from "./component/manage/ManageContact"
import AddPipeline from "./component/pipelines/AddPipeline"
import PipelinesList from "./component/pipelines/PipelinesList"
import SelectForSale from "./component/pipelines/SelectForSale"
import ManagePipeLine from "./component/pipelines/ManagePipeLine"
import ManageLead from "./component/lead/ManageLead"
import PipeLineDetails from "./component/pipelines/PipelineDetails"
import CompanyDetails from "./component/company/Company"
import ContactDetails from "./component/companyContact/ContactDetails"
import AddWantedMatch from "./component/pipelines/AddWantedMatch"
import AddListingMatch from "./component/pipelines/AddListingMatch"
import EmailDetails from "./component/customList/EmailDetails"
import MassUpload from "./component/massUpload/MassUpload"
import MassUploadW from "./component/massUploadWithNoRelation/MassUploadw"
import GlobalSearch from "./component/globalSearch/GlobalSearch"
import ManagePipeLineSales from "./component/sales/ManagePipeLineSales"
import PipeLineTaskAssignment from "./component/task/PipleLineTaskAssignment"
import BatchList from "./component/batchList/BatchList"
import ManageBatch from "./component/batchList/ManageBatch"
import AdminHome from "./component/adminHome/adminHome"
import EmailDraftDetails from "./component/adminHome/EmailDraftDetails"
import AddBroadAds from "./component/addAds/AddBroadAds"
import EditBroadCard from "./component/editCard/EditBroadCard"



function App() {
   AppContext.apiUrl = "https://ai.resochi.com"
  // AppContext.apiUrl = "http://localhost:8081"
  //AppContext.apiUrl = "http://45.77.216.104:8081"

  AppContext.frontUrl="https://resochi.com"
  AppContext.adminUrl="https://ai.resochi.com/admin/"
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<NewHome />} /> */}
        <Route path="/admin/login" element={<Login />} />
        {/* <Route path="/singup" element={<Singup />} /> */}
        <Route path="/admin/editUserInfo" element={<EditUserInfo />} />
        <Route path="/addAds" element={<AddAds />} />
        <Route path="/ads/:adsId" element={<Ads />} />
        <Route path="/editAds" element={<EditAds />} />
        <Route path="/editCard/:adsId" element={<EditCard />} />
        <Route path="/editBroadCard/:adsId" element={<EditBroadCard />} />
        <Route path="/admin" element={<AdminHome />} />
        <Route path="/admin/makes" element={<ManageMakes />} />
        <Route path="/admin/models" element={<ManageModels />} />
        <Route path="/addWantedAds" element={<AddAds wanted={true} />} />
        <Route path="/addBroadWantedAds" element={<AddBroadAds wanted={true} />} />
        <Route path="/addBroadWantedFromContact/:companyId/:contactId" element={<AddBroadAds wanted={true}/>} />
        <Route path="/wanted" element={<Home wanted={true} />} />
        <Route path="/listing" element={<Home  />} />
        <Route path="/admin/category" element={<ManageCategory />} />
        <Route path="/admin/company" element={<ManageCompany />} />
        <Route path="/admin/contact" element={<ManageContact />} />
        <Route path="/admin/company/:companyId" element={<CompanyDetails />} />
        <Route path="/admin/contact/:contactId" element={<ContactDetails />} />
        <Route path="/admin/addPipeline" element={<AddPipeline wanted={true} />} />
        <Route path="/admin/addPipelineStepTwo" element={<SelectForSale wanted={false} />} />
        <Route path="/admin/addWantedMatchForPipeline/:contactId" element={<AddWantedMatch wanted={true} />} />
        <Route path="/admin/addListingMatchForPipeline/:contactId" element={<AddListingMatch wanted={false} />} />
        <Route path="/admin/pipelineList" element={<ManagePipeLine />} />
        <Route path="/admin/pipelineList/:status" element={<ManagePipeLine />} />
        <Route path="/admin/pipeLineDetail/:pipelineId" element={<PipeLineDetails />} />
        <Route path="/admin/lead" element={<ManageLead />} />
        <Route path="/addForSaleFromContact/:companyId/:contactId" element={<AddAds />} />
        <Route path="/addWantedFromContact/:companyId/:contactId" element={<AddAds wanted={true}/>} />
        <Route path="/massUploadForSaleFromContact/:companyId/:contactId" element={<MassUpload />} />
        <Route path="/emailDetails" element={<EmailDetails />} />
        <Route path="/massUploadForSaleFromContactw/:companyId/:contactId" element={<MassUploadW />} />
        <Route path="/admin/globalSearch/:searchKey" element={<GlobalSearch />} />
        <Route path="/admin/sales" element={<ManagePipeLineSales />} />
        <Route path="/admin/task-assignment" element={<PipeLineTaskAssignment />} />
        <Route path="/admin/batchList/:batchListNumber" element={<ManageBatch />} />
        <Route path="/admin/dashboard" element={<AdminHome />} />
        <Route path="/admin/emailDraftDetail/:emailDraftId" element={<EmailDraftDetails />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
