import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect,useMemo } from "react"
import axios from "axios"
import "./company.css"
import {  useSelector } from "react-redux"
import AppContext from "../../AppContext"
import CheckboxGroup from "./CheckBoxGroup"
import { cloneDeep } from "lodash";
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import countryList from "react-select-country-list"
import Select from "react-select"
import { encodeNumberTo10CharString } from "../../NumberCodec"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"


function CompanyDetails(props) {
  const params = useParams();
  const [data, setData] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [companyProfile, setCompanyProfile] = useState();
  const [country, setCountry] = useState();
  const [state_, setState_] = useState();
  const [city, setCity] = useState();
  const [profile, setProfile] = useState();
  const [website, setwebsite] = useState();
  const [inventoryLink, setInventoryLink] = useState();
  const navigate = useNavigate();
  const options = useMemo(() => countryList().getData(), []);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();
  const [assigneeId, setAssigneeId] = useState();
  const [assigneeName, setAssigneeName] = useState();
  const [salesRepOption, setSalesRepOption] = useState();
  const [selectedSalesRepOptionValue, setSelectedSalesRepOptionValue] = useState();
  const [roles, setRoles] = useState(JSON.parse(localStorage.getItem("role")));
  const [refresh, setRefresh] = useState(false);

  const defaultType = {
    "End_User": {
      "Fab": false,
      "Lab": false,
      "University": false
    },
    "Refurbisher": false,
    "Inventory_dealer": false,
    "Broker": false,
    "Logistics": {
      "De_Install": false,
      "Removal": false,
      "Packing_Crating": false,
      "Customer_Clearence": false,
      "Forwarder": false
    },
    "OEM": false,
    "Lease_Rental": false,
    "Auctioneer": false
  };

  useEffect(() => {
    if (type !== null) {
      localStorage.setItem("company_type", JSON.stringify(type));
    }
  }, [type]);

  const token = JSON.parse(localStorage.getItem("token"));
  
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: token
    };
  });

  let id = state.user.user.id;
  let userToken = state.token;
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  };

  function getJsonInDefaultFormat(inputObj) {
    // Handle null, undefined, or empty string cases
    if (!inputObj || typeof inputObj !== 'object') {
        return { ...defaultType }; // Return a fresh copy of defaultType
    }

    const mergeDefaults = (defaultObj, inputObj) => {
        // Start with a fresh copy of the default object
        const result = { ...defaultObj };
        
        // Only process keys that exist in the default object
        Object.keys(defaultObj).forEach(key => {
            if (typeof defaultObj[key] === 'object' && !Array.isArray(defaultObj[key])) {
                // If it's a nested object, recursively merge
                result[key] = mergeDefaults(
                    defaultObj[key],
                    (inputObj && typeof inputObj[key] === 'object') ? inputObj[key] : {}
                );
            } else {
                // For boolean values, use input value if it exists, otherwise keep default
                result[key] = inputObj && typeof inputObj[key] === 'boolean' 
                    ? inputObj[key] 
                    : defaultObj[key];
            }
        });

        return result;
    };

    return mergeDefaults(defaultType, inputObj);
}

  const safelyParseJson = (jsonString) => {
    if (!jsonString) return defaultType;
    
    try {
      const parsed = JSON.parse(jsonString);
      return getJsonInDefaultFormat(parsed);
    } catch (e) {
      console.error("Failed to parse JSON", e);
      return defaultType;
    }
  };

  useEffect(() => {
    axios.get(`${AppContext.apiUrl}/api/user`, config).then(resp => {
      const salesReps = resp.data.filter(user => user.id != 1);
      const salesRepOptions = salesReps.map(user => ({
        value: user.id,
        label: user.name
      }));
      setSalesRepOption(salesRepOptions);
    });

    if (params.companyId != 0) {
      const fetchData = async () => {
        try {
          const result = await axios(`${AppContext.apiUrl}/api/company/${params.companyId}`, config);
          setData(result.data);
          // Always merge with default type when setting type data
          const typeTmp = getJsonInDefaultFormat(safelyParseJson(result.data.type));
          setType(typeTmp);
          localStorage.setItem("company_type", JSON.stringify(typeTmp));
   
          setCountry(options.find(e => e.value === result.data.country));
          setSelectedSalesRepOptionValue({
            value: result.data.assigneeId,
            label: result.data.assigneeName
          });
        } catch (error) {
          console.error("Error fetching company data:", error);
          setType(defaultType);
        }
      };
      fetchData();
    } else {
      const newData = {
        name: name,
        type: JSON.stringify(defaultType),
        country: "",
        state: state_,
        city: city,
        profile: profile,
        website: website,
        inventoryLink: inventoryLink,
        assigneeId: selectedSalesRepOptionValue?.value || 0,
        assigneeName: selectedSalesRepOptionValue?.label || ""
      };
      setData(newData);
      setType(defaultType);
      localStorage.setItem("company_type", JSON.stringify(defaultType));
    }
  }, []);
  const handleFieldChange = (field, value) => {
    setData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    if (params.companyId != 0) {
      const fetchData = async () => {
        try {
          const result = await axios(`${AppContext.apiUrl}/api/company/${params.companyId}`, config);
          setData(result.data);
          // Always merge with default type when setting type data
          const typeTmp = getJsonInDefaultFormat(safelyParseJson(result.data.type));
          setType(typeTmp);
          localStorage.setItem("company_type", JSON.stringify(typeTmp));
   
          setCountry(options.find(e => e.value === result.data.country));
          setSelectedSalesRepOptionValue({
            value: result.data.assigneeId,
            label: result.data.assigneeName
          });
        } catch (error) {
          console.error("Error fetching company data:", error);
          setType(defaultType);
          localStorage.setItem("company_type", JSON.stringify(defaultType));
        }
      };
      fetchData();
    } else {
      const newData = {
        name: name,
        type: JSON.stringify(defaultType),
        country: "",
        state: state_,
        city: city,
        profile: profile,
        website: website,
        inventoryLink: inventoryLink,
        assigneeId: selectedSalesRepOptionValue?.value || 0,
        assigneeName: selectedSalesRepOptionValue?.label || ""
      };
      setData(newData);
    }
  }, [refresh]);

  const handleSave = () => {
    const saveData = {
      name: name,
      type: localStorage.getItem("company_type"),
      country: country?.value || null,
      state: state_,
      city: city,
      profile: profile,
      website: website,
      inventoryLink: inventoryLink,
      assigneeId: selectedSalesRepOptionValue?.value || 0,
      assigneeName: selectedSalesRepOptionValue?.label || ""
    };

    if (params.companyId !== "0") {
      axios
        .put(`${AppContext.apiUrl}/api/company/${params.companyId}`, saveData, config)
        .then(() => {
          setOpen(true);
          setSeverity("success");
          setMessage("Company saved successfully.");
          setRefresh(!refresh);
        })
        .catch(e => {
          setOpen(true);
          setSeverity("error");
          setMessage("Something went wrong, please contact the admin for help.");
        });
    } else {
      axios
        .post(`${AppContext.apiUrl}/api/company`, saveData, config)
        .then((res) => {
          setOpen(true);
          setSeverity("success");
          setMessage("Company created successfully.");
          navigate(`/admin/company/${res.data.id}`);
          setRefresh(!refresh);
        })
        .catch(e => {
          setSeverity("error");
          setMessage("Something went wrong, please contact the admin for help.");
          setOpen(true);
        });
    }
  };

  const goToPublicUrl = (contactId) => {
    window.open(AppContext.adminUrl + "contact/" + encodeNumberTo10CharString(contactId), '_blank');
  };

  const transform = (data, parent) => {
    if (!data) return [];

    return Object.keys(data).map((key) => {
      const value = data[key];
      const node = {
        label: key,
        checked: false,
        childrenNodes: [],
        parent: parent,
      };

      if (typeof value === "boolean") {
        node.checked = value;
      } else if (typeof value === "object" && value !== null) {
        const children = transform(value, node);
        node.childrenNodes = children;
        node.checked = children.length > 0 && children.every((child) => child.checked);
      }

      return node;
    });
  };

  const reverseTransform = (nodes) => {
    const result = {};
    nodes.forEach(node => {
      if (node.childrenNodes.length > 0) {
        result[node.label] = reverseTransform(node.childrenNodes);
      } else {
        result[node.label] = node.checked;
      }
    });
    return result;
  };

  const updateAncestors = (node) => {
    if (!node.parent) return;

    const parent = node.parent;
    if (parent.checked && !node.checked) {
      parent.checked = false;
      updateAncestors(parent);
    } else if (!parent.checked && node.checked) {
      if (parent.childrenNodes.every((child) => child.checked)) {
        parent.checked = true;
        updateAncestors(parent);
      }
    }
  };

  const toggleDescendants = (node) => {
    const checked = node.checked;
    node.childrenNodes.forEach((child) => {
      child.checked = checked;
      toggleDescendants(child);
    });
  };

  const findNode = (nodes, label, ancestors) => {
    if (!nodes || nodes.length === 0) return undefined;
    
    if (ancestors.length === 0) {
      return nodes.find((node) => node.label === label);
    }

    let currentNode;
    for (const ancestor of ancestors) {
      const candidates = currentNode ? currentNode.childrenNodes : nodes;
      currentNode = candidates.find((node) => node.label === ancestor);
      if (!currentNode) return undefined;
    }
    
    return currentNode.childrenNodes.find((node) => node.label === label);
  };

  const NestedCheckbox = ({ data }) => {
    // Ensure we're working with properly merged data
    const safeData = getJsonInDefaultFormat(
        typeof data === 'object' && data !== null ? data : defaultType
    );
    const initialNodes = transform(safeData);
    const [nodes, setNodes] = useState(initialNodes);

    const handleBoxChecked = (e, ancestors) => {
        const checked = e.currentTarget.checked;
        const node = findNode(nodes, e.currentTarget.value, ancestors);

        if (node) {
            node.checked = checked;
            toggleDescendants(node);
            updateAncestors(node);
            setNodes(cloneDeep(nodes));
        }
    };

    useEffect(() => {
        if (nodes.length > 0) {
            const transformedData = reverseTransform(nodes);
            const mergedData = getJsonInDefaultFormat(transformedData);
            localStorage.setItem("company_type", JSON.stringify(mergedData));
        }
    }, [nodes]);
 
    return (
        <NestedCheckboxHelper 
            nodes={nodes} 
            ancestors={[]} 
            onBoxChecked={handleBoxChecked}
        />
    );
};

  const NestedCheckboxHelper = ({ nodes, prefix = 'root', ancestors = [], onBoxChecked }) => {
    return (
      <div className="checkbox-group">
        {nodes.map(({ label, checked, childrenNodes }) => {
          const id = `${prefix}.${label}`;
          const children = childrenNodes.length > 0 ? (
            <NestedCheckboxHelper
              nodes={childrenNodes}
              prefix={id}
              ancestors={[...ancestors, label]}
              onBoxChecked={onBoxChecked}
            />
          ) : null;

          return (
            <div className="checkbox-item" key={id}>
              <input 
                type="checkbox" 
                name={id} 
                value={label} 
                checked={checked} 
                onChange={(e) => onBoxChecked(e, ancestors)}
              />
              <label 
                style={{marginBottom:"1px", marginTop:"1px"}} 
                htmlFor={id}
              >
                {label}
              </label>
              {children}
            </div>
          );
        })}
      </div>
    );
  };
  /** end of check box group */
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      <Navbar />
      {data && (
        
        <div style={{marginLeft:"5%"}}>
        <div style={{textAlign:"center",fontWeight:"bolder"}}><h3>Company details page</h3></div>
        {/* <CheckboxGroup /> */}
       
  <div><label>Name:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.name} onChange={(e) => setName(e.target.value)}></input></div>
        <div><label>Company Profile:</label></div>
        {/* companyProfile:companyProfile,
      country:country,
      state:state_,
      city:city,
      profile:profile,
      website:website,
      inventoryLink:inventoryLink */}
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><textarea rows="10" cols="300" type="text"  defaultValue={data.profile} onChange={e=>setProfile(e.target.value)}></textarea></div>
  <div>
            <label>country</label>
          </div>
          <Select options={options} value={country} onChange={e=>setCountry(e)} />
  <div><label>state:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.state} onChange={e=>setState_(e.target.value)}></input></div>
  <div><label>city:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.city} onChange={e=>setCity(e.target.value)}></input></div>
  
  <div><label>website:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.website} onChange={e=>setwebsite(e.target.value)}></input></div>
  <div><label>inventoryLink:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.inventoryLink} onChange={e=>setInventoryLink(e.target.value)}></input></div>
 
 {roles &&roles.name && roles.name[0]!="sales_rep" &&(
            <>
            <div>
              <label>Account Manager:</label>
            </div>
            <Select options={salesRepOption} value={selectedSalesRepOptionValue} onChange={e => setSelectedSalesRepOptionValue(e)} />
            </>
            )}
 <div><label>Company Type:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}>{data ?<NestedCheckbox data={JSON.parse(data.type || '{}')} ancestors={[]}/>:"loading"}</div>
  
<div><Button onClick={handleSave}>Save</Button></div>
        <hr/>
        {params.companyId!="0" && (
        <div><Button onClick={()=>window.open(`/admin/contact/0?companyId=${params.companyId}`,'_blank')}>Create New Contact</Button></div>
        )}
        <div><label>ContactList:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left",
  marginBottom:"20px"}}>
    <table style={{width:"100%"}}><tr><td><b>first name</b></td><td><b>last name</b></td>
  <td><b>Note</b></td><td><b>Connection note</b></td>
  <td><b>Title</b></td><td><b>Cell phone</b></td>
  <td><b>priority</b></td><td><b>Next step</b></td>
  <td><b>wanted</b></td><td><b>for sale</b></td>
  <td><b>Way We Communicate</b></td>
  </tr>
    {data && data.contact&& data.contact.map(item=>(
      <tr  onClick={()=>goToPublicUrl(item.id)} style={{textDecoration:"underLine",cursor:"pointer"}} ><td>{item.firstName}</td><td>{item.lastName}</td>
      <td>{item.note}</td><td>{item.connectionNote}</td>
      <td>{item.title}</td><td>{item.cellPhone}</td>
      <td>{item.priority}</td><td>{item.nextStep}</td>
      <td>{item.wanted}</td><td>{item.forSale}</td>
      <td>{item.wayWeCommunicate}</td>
      </tr>
      ))}
    </table>
  </div>

        </div>
        
        
      )}
    </div>
  )
}

export default CompanyDetails
