import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomId, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { encodeNumberTo10CharString } from "../../NumberCodec"
import { useState } from "react"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"



// const initialRows = [
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 25,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 36,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 19,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 28,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 23,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   }
// ]

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/admin/contact/0`)
  }

  return (
    <GridToolbarContainer>
      {/* <Button color="primary" onClick={handleClick}>
        Add Model record
      </Button> */}
    </GridToolbarContainer>
  )
}

export default function ManageContactList(props) {
  const [rows, setRows] = React.useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})
  const [makesList, setMakesList] = React.useState([])

  const navigate = useNavigate()
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))
  
  // const hasNonSalesRepRole = roles.some(role => {
  //   return typeof role.name === 'string' && role.name !== "sales_rep";
  // });
  const isAdmin = () => {
    return roles.name.includes("user");
  };
  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const goToDetails = (rowSelected) =>{
    //alert(rowSelected.id)
    //localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
    if(isAdmin()||user.name===rowSelected.row.assigneeName || rowSelected.row.assigneeName===null){
      window.open(AppContext.adminUrl+"contact/"+encodeNumberTo10CharString(rowSelected.id),'_blank')
      }else{
        setSeverity("warning")
        setMessage("Please note that you don't have permission to view this company details because it's not assigned to you.")
        setOpen(true)
      }
      
    
//    navigate(`/admin/contact/${encodeNumberTo10CharString(rowSelected.id)}`)
  }
  // const transformInput = input => {
  //   return input.map(item => ({
  //     id: item.id,
  //     categoryName: item.name,
  //     modelName: item.model ? item.model.name : "dummy",
  //     makeName: item.makes ? item.makes.name : "dummy"
  //   }))
  // }

  function arrayToObject(arr) {
    return {
      id: arr[0],
      firstName: arr[5],
      lastName: arr[6],
      priority: arr[1],
      nextStep: arr[2],
      cellPhone: arr[7],
      //accountManagerName: arr[4],
      contactEmail: arr[8],
      companyName: arr[9],
      assigneeName: arr[10],
      companyAssigneeId:arr[11]
    }
  }
  //call api to get the make list on data load
  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/contacts`, config)
    let medium = (result.data.map(arrayToObject)).filter(e=>e.companyAssigneeId==user.id)
    
    if(!isAdmin()){
      setRows(medium)
    }else{
      setRows(result.data.map(arrayToObject))
    }
  }, [])

  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/contacts`, config)

    let medium = (result.data.map(arrayToObject)).filter(e=>e.companyAssigneeId==user.id)
    
    if(!isAdmin()){
      setRows(medium)
    }else{
      setRows(result.data.map(arrayToObject))
    }
  }, [props.refreshNeeded])

  React.useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/makes`)
    //const makes = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    setMakesList(result.data)
  }, [])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => () => {
    setRows(rows.filter(row => row.id !== id))
    axios.delete(`${AppContext.apiUrl}/api/contact/${id}`, config).then(()=>{
      setOpen(true)
      setSeverity("success")
      setMessage("Contact deleted successfully.")
      
    }
  )
  .catch(e => {
    setOpen(true)
      setSeverity("error")
      setMessage("Something went wrong, please make sure there is no for sale, wanted that are connected with this contact.")
  })
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    console.log(newRow.id, newRow.name)

    axios
      .put(`${AppContext.apiUrl}/api/contact/${newRow.id}/null`, { id: newRow.id, contactName: newRow.contactName, contactPhone: newRow.contactPhone, contactEmail: newRow.contactEmail }, config)
      .then()
      .catch(e => {
        console.log("something happend when editing contact")
      })

    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }
  const columns = [
    { field: "firstName", headerName: "First name", width: 170, editable: true },
    { field: "lastName", headerName: "Last name", width: 170, editable: true },
    { field: "priority", headerName: "Priority", width: 180, editable: true },
    // { field: "assigneeName", headerName: "Assignee", width: 100, editable: true },
    //{ field: "accountManagerName", headerName: "account manager", width: 180, editable: false },
    { field: "nextStep", headerName: "next step", width: 180, editable: false },
    { field: "cellPhone", headerName: "cell phone", width: 180, editable: false },
    { field: "contactEmail", headerName: "email", width: 180, editable: false },
    { field: "companyName", headerName: "Company", width: 180, editable: false },
    // { field: "companyName", headerName: "Company", width: 180, editable: false },
    //c.id,c.priority,c.account_manager_name,
    //c.first_name,c.last_name,c.cell, c.contact_email, com.name
    // {
    //   field: "make",
    //   headerName: "Make",
    //   width: 420,
    //   editable: true,
    //   type: "singleSelect",
    //   renderCell: params => (
    //     <select
    //       style={{ width: "400px" }}
    //       //value={params.value[0] ? params.value[0].id : ""} // Assuming params.value contains the selected model
    //       value={{ id: params.row.model && params.row.model.length > 0 ? params.row.model[0].id : "", name: params.row.model && params.row.model.length > 0 ? params.row.model[0].name : "" }} // Assuming params.value contains the selected model
    //       onChange={e => {
    //         // Handle the model change here
    //         const newValue = e.target.value
    //         console.log("------params---" + params)
    //         // You may want to call an update function to update your data
    //         // For example: updateModel(params.id, newValue)
    //       }}
    //     >
    //       {makesList &&
    //         makesList.map(modelOption => (
    //           <option key={modelOption ? modelOption.id : ""} id={modelOption ? modelOption.id : ""} value={modelOption ? modelOption.name : ""}>
    //             {modelOption ? modelOption.name : ""}
    //           </option>
    //         ))}
    //     </select>
    //   )
    // },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ( params ) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(params.id)} color="inherit" />
          ]
        }
        if(!isAdmin() && (user.name!=params.row.assigneeName)) return []
        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ]
      }
    }
  ]

  return (
    <Box
      sx={{
        // height: 500,
        display: 'inline-block',
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="cell"
        onRowClick={goToDetails}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
      />
    </Box>
  )
}
