import { Link, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import jwt_decode from "jwt-decode"
import { addUser, addToken } from "../../reducers/user/action"
import axios from "axios"
import Navbar from "../navbar/Navbar"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./addAds.css"
import ProductCategory from "../productCategory/ProductCategory"
import CompanyList from "../company/CompanyList"
import Makes from "../productMakes/Makes"
import Models from "../model/Models"
import CompanyContactList from "../companyContact/CompanyContactList"
import Select from "react-select"
import countryList from "react-select-country-list"
import AppContext from "../../AppContext"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import FileUploader from "../FileUploader"
import Category from "../category/Category"
import UploadFile from "../UploadFile"
import FileUpload from "../FileUpload"
import InternalFileUpload from "../InternalFileUpload"
import ExternalFileUpload from "../ExternalFileUpload"
import MakeList from "../productMakes/MakeList"
import ModelSelectionComponent from "../ModelSelectionComponent"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"


function AddBroadAds(props) {
  const params = useParams()
  const [adsTitle, setAdsTitle] = useState()
  const [country, setCountry] = useState()
  const [price, setPrice] = useState(0)
  const [adsLocation, setadsLocation] = useState()
  const [adsDescription, setadsDescription] = useState()
  const [adsImage, setadsImage] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [makesOptions, setMakesOptions] = useState()
  const [makesList, setMakesList] = useState()
  const [modelsOptions, setModelsOptions] = useState()
  const [category, setCategory] = useState()
  const [categoryName, setCategoryName] = useState()
  const [contactsOptions, setContactsOptions] = useState()
  const [contacts1Options, setContacts1Options] = useState()
  const [contacts2Options, setContacts2Options] = useState()
  const [contacts3Options, setContacts3Options] = useState()
  const [contactsRealOwnerOptions, setContactsRealOwnerOptions] = useState()
  const [companyOptions, setCompanyOptions] = useState()
  const [companyList, setCompanyList] = useState()
  const [waferSizeOptions, setWaferSizeOptions] = useState()
  const [selectedWaferSizeValue, setSelectedWaferSizeValue] = useState()
  const [configuration, setConfiguration] = useState()
  const [note, setNote] = useState()
  const [vintage, setVintage] = useState()
  const [serialNumber, setSerialNumber] = useState()

  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])

  // const [images, setImages] = useState([])
  const [imagesUrls, setImagesUrls] = useState([])
  const [hideFromFrontEnd, setHideFromFrontEnd] = useState(false)
  const [adsType, setAdsType] = useState()
  const [featuredItem, setFeaturedItem] = useState(false)

  const [internalFilesUrls, setInternalFilesUrls] = useState([])
  const [externalFilesUrls, setExternalFilesUrls] = useState([])

  const [state_, setState_] = useState()
  const [city, setCity] = useState()
  const [quantity, setQuantity] = useState()
  const [process, setProcess] = useState()
  const [availableDate, setAvailableDate] = useState()
  const [statusOptions, setStatusOptions] = useState([])
  const [selectedStatusValue, setSelectedStatusValue] = useState( { value: 'ACTIVE', label: 'Active' })
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  const [exclusive, setExclusive] = useState(false)



  const changeHandler = value => {
    setValue(value)
  }
  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  useEffect(() => {
    if (props.wanted) {
      setAdsType(props.wanted ? 1 : 0)
    }
  }, [props.wanted])

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }

  const title = e => {
    setAdsTitle(e.target.value)
  }
  

  const description = e => {
    setadsDescription(e.target.value)
  }

  // const categoryRealEstate = e => {
  //   e.preventDefault()

  //   category = 2
  //   document.querySelector("#categoryTitle").innerHTML = "real estate"
  // }

  // const categoryCar = e => {
  //   e.preventDefault()

  //   category = 3
  //   document.querySelector("#categoryTitle").innerHTML = "car"
  // }

  // const categoryDevices = e => {
  //   e.preventDefault()

  //   category = 4
  //   document.querySelector("#categoryTitle").innerHTML = "devices"
  // }

  // const uploadImage = async file => {
  //   // const files = e.target.files
  //   const data = new FormData()
  //   data.append("file", file)
  //   data.append("upload_preset", "adsimage")
  //   setLoading(true)

  //   const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data).then(res => {
  //     file = true
  //     setImage(res.data.secure_url)
  //     let image_urls = imagesUrls
  //     setImagesUrls(...imagesUrls, res.data.secure_url)
  //     setLoading(false)
  //   })
  // }

  const postAds = e => {
    e.preventDefault()
    const data = {
      title: adsTitle,
      country: value.value,
      description: adsDescription,
      image: imagesUrls.join("$"),
      internalFiles: internalFilesUrls.join("$"),
      externalFiles: externalFilesUrls.join("$"),
      location: adsLocation,
      hideFromFrontEnd: hideFromFrontEnd,
      user: {
        id: id
      },
      category: selectedCategoryValue?{
        id: selectedCategoryValue ? selectedCategoryValue.value : 0
      }:null,
      company: {
        id: selectedCompanyValue ? selectedCompanyValue.value : 1
      },
      contact: {
        id: selectedCompanyContactValue ? selectedCompanyContactValue.value : 1
      },
      make: selectedMakeValue?{
        id: selectedMakeValue ? selectedMakeValue.value : 0
      }:null,
      model: selectedModelValue?{
        id: selectedModelValue ? selectedModelValue.value : 0
      }:null,
      price: price ? price.replace("$", "").replace(",", "") : 0,
      status: selectedStatusValue ? selectedStatusValue.value : "ACTIVE",
      waferSize: selectedWaferSizeValue ? selectedWaferSizeValue.value : "",
      configuration: configuration,
      note: note,
      vintage: vintage,
      serialNumber: serialNumber,
      type: adsType,
      feature:featuredItem?1:0,
      state:state_,
      city:city,
      quantity:quantity,
      process:process,
      availableDate:availableDate,
      referal1Company:selectedCompany1Value?selectedCompany1Value.value:null,
      referal1Contact:selectedCompany1ContactValue?selectedCompany1ContactValue.value:null,
      referal2Company:selectedCompany2Value?selectedCompany2Value.value:null,
      referal2Contact:selectedCompany2ContactValue?selectedCompany2ContactValue.value:null,
      referal3Company:selectedCompany3Value?selectedCompany3Value.value:null,
      referal3Contact:selectedCompany3ContactValue?selectedCompany3ContactValue.value:null,
      referalRealOwnerCompany:selectedRealOwnerCompanyValue?selectedRealOwnerCompanyValue.value:null,
      referalRealOwnerContact:selectedRealOwnerCompanyContactValue?selectedRealOwnerCompanyContactValue.value:null,
      userId:id,
      exclusive:exclusive,


    }

    axios
      .post(`${AppContext.apiUrl}/api/ads`, data, config)
      .then(response => {
        console.log(response.data)
        
        
          setOpen(true)
          setSeverity("success")
          setMessage("Listing created successfully.")
          // navigate(`/editBroadCard/${response.data.id}`)
          navigate(`/wanted`)        
        
      })
      .catch(error => {
        console.log("error happend: ", error)
        //navigate("/login")
        setOpen(true)
          setSeverity("error")
          setMessage("Something went wrong, please contact the admin for help")
        })
  }
  const stateValue = { value: "Ion Implanters", label: "Ion Implanters" }

  const [selectedMakeValue, setSelectedMakeValue] = useState("")

  const handleSelectMakeChange = value => {
    setSelectedMakeValue(value)
    console.log("make is ", value)
  }

  const [selectedModelValue, setSelectedModelValue] = useState("")

  const handleSelectModelChange = value => {
    setSelectedModelValue(value)
    console.log("model is ", value)
  }

  const [selectedCategoryValue, setSelectedCategoryValue] = useState("")

  const handleSelectCategoryChange = value => {
    setSelectedCategoryValue(value)
    console.log("category is ", value)
  }

  const [selectedCompanyValue, setSelectedCompanyValue] = useState("")

  const handleSelectCompanyChange = value => {
    setSelectedCompanyValue(value)
    console.log("company is ", value)
  }

  const [selectedCompanyContactValue, setSelectedCompanyContactValue] = useState("")

  const handleSelectCompanyContactChange = value => {
    setSelectedCompanyContactValue(value)
    console.log("contact is ", value)
  }

  // refer1
  const [selectedCompany1Value, setSelectedCompany1Value] = useState("")

  const handleSelectCompany1Change = value => {
    setSelectedCompany1Value(value)
    console.log("company1 is ", value)
  }

  const [selectedCompany1ContactValue, setSelectedCompany1ContactValue] = useState("")

  const handleSelectCompany1ContactChange = value => {
    setSelectedCompany1ContactValue(value)
    console.log("contact1 is ", value)
  }

  //refer2
  const [selectedCompany2Value, setSelectedCompany2Value] = useState("")

  const handleSelectCompany2Change = value => {
    setSelectedCompany2Value(value)
    console.log("company2 is ", value)
  }

  const [selectedCompany2ContactValue, setSelectedCompany2ContactValue] = useState("")

  const handleSelectCompany2ContactChange = value => {
    setSelectedCompany2ContactValue(value)
    console.log("contact2 is ", value)
  }

  //refer3
  const [selectedCompany3Value, setSelectedCompany3Value] = useState("")

  const handleSelectCompany3Change = value => {
    setSelectedCompany3Value(value)
    console.log("company3 is ", value)
  }

  const [selectedCompany3ContactValue, setSelectedCompany3ContactValue] = useState("")

  const handleSelectCompany3ContactChange = value => {
    setSelectedCompany3ContactValue(value)
    console.log("contact3 is ", value)
  }

  //real owner
  const [selectedRealOwnerCompanyValue, setSelectedRealOwnerCompanyValue] = useState("")

  const handleSelectRealOwnerCompanyChange = value => {
    setSelectedRealOwnerCompanyValue(value)
    console.log("realowner is ", value)
  }

  const [selectedRealOwnerCompanyContactValue, setSelectedRealOwnerCompanyContactValue] = useState("")

  const handleSelectRealOwnerCompanyContactChange = value => {
    setSelectedRealOwnerCompanyContactValue(value)
    console.log("real owner contact is ", value)
  }

  useEffect( () => {
    // Try to load makes options from localStorage

    // const cachedOptions = localStorage.getItem('makesList');
      
    // if (cachedOptions) {
    //   const _list = JSON.parse(cachedOptions)
    //   setMakesList(_list);
    //   const category = _list.map(cat => ({ value: cat.id, label: cat.name }))
    // //console.log("category " + category)
    //     setMakesOptions(category)
    // } else {
    //   try {
    //     //const fetchedOptions = await fetchOptions();
    //     axios(`${AppContext.apiUrl}/api/makes`).then(res=>{
    //       updateLocalStorageItem('makesList', JSON.stringify(res.data));
    //       setMakesList(res.data)
    //       const category = res.data.map(cat => ({ value: cat.id, label: cat.name }))
    //   //console.log("category " + category)
    //       setMakesOptions(category)
    //     })
    //   } catch (error) {
    //     console.error('Failed to fetch options:', error);
    //     // Handle failure (possibly set options to a default value or show an error)
    //   }
    // }
    

    

    //set warfsize
    setWaferSizeOptions([
      { value: '4"', label: '4"' },
      { value: '5"', label: '5"' },
      { value: '6"', label: '6"' },
      { value: '8"', label: '8"' },
      { value: '12"', label: '12"' }
    ])

    //set status
    // setStatusOptions([
    //   { value: 'ACTIVE', label: 'Active' },
    //   { value: 'PENDING', label: 'Pending' },
    //   { value: 'SOLD', label: 'Sold' }
    // ])
    const options = [
      { value: 'ACTIVE', label: 'Active' },
      { value: 'PENDING', label: 'Pending' },
      { value: 'SOLD', label: 'Sold' }
    ];
    setStatusOptions(options);

    // Set the default status to 'ACTIVE'
    setSelectedStatusValue(options[0]);

    if(params.companyId){
      setSelectedCompanyValue({value:params.companyId,label:params.companyId})
    }
    if(params.contactId){
      setSelectedCompanyContactValue({value:params.contactId,label:params.contactId})
    }
  }, [])

  useEffect( () => {
    // Try to load update if older then 30days
  const  key = "makesList"
    // Get the current timestamp
  const currentDate = new Date();
  
  // Retrieve the last updated date from localStorage
  // const lastUpdatedDateString = localStorage.getItem(`${key}_lastUpdated`);
  const lastUpdatedDateString = null;
  
  if (lastUpdatedDateString) {
    const lastUpdatedDate = new Date(lastUpdatedDateString);
    
    // Calculate the difference in milliseconds between current date and last updated date
    const timeDiff = currentDate - lastUpdatedDate;
    
    // Calculate the number of days
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    
    // Check if last update was more than 30 days ago
    if (daysDiff >= 30) {
      const cachedOptions = localStorage.getItem('makesList');
      const _list = JSON.parse(cachedOptions)
      setMakesList(_list);
      const category = _list.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
        setMakesOptions(category)
    } else {
      try {
        //const fetchedOptions = await fetchOptions();
        axios(`${AppContext.apiUrl}/api/makes`).then(res=>{
          updateLocalStorageItem('makesList', JSON.stringify(res.data));
          setMakesList(res.data)
          const category = res.data.map(cat => ({ value: cat.id, label: cat.name }))
      //console.log("category " + category)
          setMakesOptions(category)
        }

        )
        
      } catch (error) {
        console.error('Failed to fetch options:', error);
        // Handle failure (possibly set options to a default value or show an error)
      }
    }}else {
      try {
        //const fetchedOptions = await fetchOptions();
        axios(`${AppContext.apiUrl}/api/makes`).then(res=>{
          updateLocalStorageItem('makesList', JSON.stringify(res.data));
          setMakesList(res.data)
          const category = res.data.map(cat => ({ value: cat.id, label: cat.name }))
      //console.log("category " + category)
          setMakesOptions(category)
        }

        )
      } catch (error) {
        console.error('Failed to fetch options:', error);
        // Handle failure (possibly set options to a default value or show an error)
      }
    }
    
  }, [])

  // Function to update localStorage item and record the last updated date
// Function to update localStorage item if last update was more than 30 days ago
function updateLocalStorageItem(key, newValue) {
  // Get the current timestamp
  const currentDate = new Date();
  
  // Retrieve the last updated date from localStorage
  const lastUpdatedDateString = localStorage.getItem(`${key}_lastUpdated`);
  
  if (lastUpdatedDateString) {
    const lastUpdatedDate = new Date(lastUpdatedDateString);
    
    // Calculate the difference in milliseconds between current date and last updated date
    const timeDiff = currentDate - lastUpdatedDate;
    
    // Calculate the number of days
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    
    // Check if last update was more than 30 days ago
    if (daysDiff >= 30) {
      // Update the item in localStorage
      localStorage.setItem(key, JSON.stringify(newValue));
      
      // Store the current date as the last updated date
      localStorage.setItem(`${key}_lastUpdated`, currentDate.toISOString());
      
      console.log('Item updated successfully.');
    } else {
      console.log('Item was last updated less than 30 days ago. No need to update.');
    }
  } else {
    // If last update date is not found, update the item and store current date as last updated
    localStorage.setItem(key, JSON.stringify(newValue));
    localStorage.setItem(`${key}_lastUpdated`, currentDate.toISOString());
    
    console.log('Item updated successfully for the first time.');
  }
}

// Example usage:


  useEffect(async () => {
    
      //need to make call by makes id from model and set model options
      let result = await axios(`${AppContext.apiUrl}/api/models`).then(
        res=>{
            
              const modelsList = res.data.map(cat => ({ value: cat.id, label: cat.name }))
              // Create a Set to track unique labels
const uniqueLabels = new Set();
const filteredModelsList = modelsList.filter(model => {
  if (uniqueLabels.has(model.label)) {
    return false; // Skip if the label is already in the Set
  } else {
    uniqueLabels.add(model.label); // Add new label to the Set
    return true; // Include this model in the filtered list
  }
});
              setModelsOptions(filteredModelsList)
            
          
        }
      )

      
    
  }, [])

  // useEffect(async () => {
  //   if (selectedModelValue && selectedModelValue !== 0) {
  //     let result = await axios(`${AppContext.apiUrl}/api/category/byMakeModel/${selectedMakeValue.value}/${selectedModelValue.value}`)

  //     //const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
  //     //console.log("category " + category)
  //     // if (selectedModelValue.value !== 0) {
  //     setSelectedCategoryValue({ value: result.data.id, label: result.data.name })
  //     //setCategoryName(result.data.name)
  //     // } else {
  //     // }
  //   }
  // }, [selectedModelValue])

  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/company`, config)
    setCompanyList(result.data)
    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setCompanyOptions(category)
  }, [])

  // useEffect(() => {
  //   async function uploadImg(file) {
  //     const data = new FormData()
  //     data.append("file", file)
  //     data.append("upload_preset", "adsimage")
  //     setLoading(true)

  //     const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data).then(res => {
  //       file = true
  //       //setImage(res.data.secure_url)
  //       let image_urls = imagesUrls
  //       setImagesUrls(url => [...url, res.data.secure_url])
  //       setLoading(false)
  //       console.log("------parent image change res.data.secure_url--------------->>>")
  //       console.log(res.data.secure_url)
  //     })
  //   }
  //   if (images) {
  //     images.forEach(element => {
  //       uploadImg(element)
  //     })
  //   }
  // }, [images])

  // useEffect(() => {
  //   console.log("-----imagesUrls---------------->>>")
  //   console.log(imagesUrls)
  //   //update image field
  //   //setImage(imagesUrls.join("$"))
  // }, [imagesUrls])

  useEffect(() => {
    if (selectedCompanyValue && params.companyId===undefined) {
      companyList.filter(company => {
        if (company.id === selectedCompanyValue.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContactsOptions(contactList)
        }
      })
    }
  }, [selectedCompanyValue])

  useEffect(() => {
    if (selectedCompany1Value && params.companyId===undefined) {
      companyList.filter(company => {
        if (company.id === selectedCompany1Value.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContacts1Options(contactList)
        }
      })
    }
  }, [selectedCompany1Value])

  useEffect(() => {
    if (selectedCompany2Value && params.companyId===undefined) {
      companyList.filter(company => {
        if (company.id === selectedCompany2Value.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContacts2Options(contactList)
        }
      })
    }
  }, [selectedCompany2Value])

  useEffect(() => {
    if (selectedCompany3Value && params.companyId===undefined) {
      companyList.filter(company => {
        if (company.id === selectedCompany3Value.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContacts3Options(contactList)
        }
      })
    }
  }, [selectedCompany3Value])

  useEffect(() => {
    if (selectedRealOwnerCompanyValue && params.companyId===undefined) {
      companyList.filter(company => {
        if (company.id === selectedRealOwnerCompanyValue.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContactsRealOwnerOptions(contactList)
        }
      })
    }
  }, [selectedRealOwnerCompanyValue])

  return (
    <div>
       <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      
      <Navbar />
      <form>
        <div className="ads-from">
          <div>
            <label>{props.wanted ? "Wanted" : "Selling"} Ads title</label>
          </div>
          <input  type="text" defaultValue={adsTitle} autoFocus onChange={title} name="title" id="title_id" />
          <div>
            <label>Status:</label>
          </div>
          <Select options={statusOptions} value={selectedStatusValue} onChange={e => setSelectedStatusValue(e)} />
          <div>
            <label>Hide from front end?</label>
          </div>
          <div>
            <div>
              Yes
              <input type="radio" name="hideFromFrontEnd" value="true" onChange={e => setHideFromFrontEnd(true)} checked={hideFromFrontEnd === true} />{" "}
            </div>
            <div>
              No <input type="radio" name="hideFromFrontEnd" value="false" onChange={e => setHideFromFrontEnd(false)} checked={hideFromFrontEnd === false} />
            </div>
          </div>

          <div>
            <label>Exclusive listing?</label>
          </div>
          <div>
            <div>
              Yes
              <input type="radio" name="exclusive" value="true" onChange={e => setExclusive(true)} checked={exclusive === true} />{" "}
            </div>
            <div>
              No <input type="radio" name="exclusive" value="false" onChange={e => setExclusive(false)} checked={exclusive === false} />
            </div>
          </div>

          <div>
            <label>Featured Item? will show in Featured list if Yes is selected</label>
          </div>
          <div>
            <div>
              Yes
              <input type="radio" name="feature" value="true" onChange={e => setFeaturedItem(true)} checked={featuredItem === true} />{" "}
            </div>
            <div>
              No <input type="radio" name="feature" value="false" onChange={e => setFeaturedItem(false)} checked={featuredItem === false} />
            </div>
          </div>
          <div>{/* Yes <input onChange={setHideFromFrontEnd(true)} type="radio" checked={hideFromFrontEnd} name="hideFromFrontEnd" id="hideYes_id" /> No <input type="radio" checked={hideFromFrontEnd} name="hideFromFrontEnd" id="hide_id" /> */}</div>
          <div>
          
            <label>
              Makes:
            </label>
          </div>
          <div>
            <Makes options={makesOptions} selectedValue={selectedMakeValue} onSelectChange={handleSelectMakeChange} />
          </div>
          <div>
            <label>
              Model:
            </label>
          </div>
          {/* stateValue={{ value: "PI 9500", label: "PI 9500" }}  */}
          {(selectedMakeValue===""||(selectedMakeValue && selectedMakeValue.label!=="unknown")) &&(
          <div>
            <Models options={modelsOptions} selectedValue={selectedModelValue} onSelectChange={handleSelectModelChange} />
          </div>)}
          {selectedMakeValue && selectedMakeValue.label==="unknown" &&(
          <div><ModelSelectionComponent selectedValue={selectedModelValue} onSelectionChange={handleSelectModelChange} /></div>)}
          <div>
            <label>
              Category:
            </label>
          </div>
          {/* {props.wanted ? <Category selectedValue={selectedCategoryValue} onSelectChange={handleSelectCategoryChange} /> : <input type="text" disabled autoFocus value={categoryName} name="category" id="category_id" />} */}
          <Category selectedValue={selectedCategoryValue} onSelectChange={handleSelectCategoryChange} />

          <div>
            <label>Description</label>
          </div>
          <textarea rows="4" value={adsDescription} cols="50" placeholder="" name="descritpion" id="des_id" onChange={description} />
          {/* <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              Category
            </button>
            <ul className="dropdown-menu">
              <button className="dropdown-item" type="button" onClick={categoryRealEstate}>
                backend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryCar}>
                frontend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryDevices}>
                devices
              </button>
            </ul>
            <h5 id="categoryTitle"></h5>
          </div> */}
          {!params.companyId?(
            <>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompanyValue} onSelectChange={handleSelectCompanyChange} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contactsOptions} selectedValue={selectedCompanyContactValue} onSelectChange={handleSelectCompanyContactChange} />
          </div>
          </>
          ):""}
          {/* /* referal 1 */ }
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 1 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany1Value} onSelectChange={handleSelectCompany1Change} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 1 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts1Options} selectedValue={selectedCompany1ContactValue} onSelectChange={handleSelectCompany1ContactChange} />
          </div>

          {/* /* referal 2 */ }
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 2 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany2Value} onSelectChange={handleSelectCompany2Change} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 2 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts2Options} selectedValue={selectedCompany2ContactValue} onSelectChange={handleSelectCompany2ContactChange} />
          </div>

{/* /* referal 3 */ }
<div>
            <label>
              <span style={{ color: "red" }}></span>Referal 3 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany3Value} onSelectChange={handleSelectCompany3Change} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 3 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts3Options} selectedValue={selectedCompany3ContactValue} onSelectChange={handleSelectCompany3ContactChange} />
          </div>

{/* /* real owner */ }
<div>
            <label>
              <span style={{ color: "red" }}></span>Real Owner Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedRealOwnerCompanyValue} onSelectChange={handleSelectRealOwnerCompanyChange} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Real Owner Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contactsRealOwnerOptions} selectedValue={selectedRealOwnerCompanyContactValue} onSelectChange={handleSelectRealOwnerCompanyContactChange} />
          </div>

          <div>
            <label>Wafer Size:</label>
          </div>
          <Select options={waferSizeOptions} value={selectedWaferSizeValue} onChange={e => setSelectedWaferSizeValue(e)} />
          <div>
            <label>Configuration</label>
          </div>
          <input type="text" value={configuration} name="configuration" id="configuration_id" placeholder="" onChange={e => setConfiguration(e.target.value)} />
          <div>
            <label>note</label>
          </div>
          <input type="text" value={note} name="note" id="note_id" placeholder="" onChange={e => setNote(e.target.value)} />
          <div>
            <label>vintage</label>
          </div>
          <input type="text" value={vintage} name="vintage" id="vintage_id" placeholder="Put only numbers" onChange={e => setVintage(e.target.value)} />
          <div>
            <label>serial number</label>
          </div>
          <input type="text" value={serialNumber} name="serialNumber" id="serialNumber_id" placeholder="" onChange={e => setSerialNumber(e.target.value)} />
          
          <div>
            <label>country</label>
          </div>
          <Select options={options} value={value} onChange={changeHandler} />
          <div>
            <label>State:</label>
          </div>
          <input type="text" value={state_} name="state" id="state_id" placeholder="" onChange={e => setState_(e.target.value)} />
          <div>
            <label>City</label>
          </div>
          <input type="text" value={city} name="city" id="city_id" placeholder="" onChange={e => setCity(e.target.value)} />
          <div>
            <label>Quantity:</label>
          </div>
          <input type="text" value={quantity} name="quantity" id="quantity_id" placeholder="" onChange={e => setQuantity(e.target.value)} />
          <div>
            <label>Process:</label>
          </div>
          <input type="text" value={process} name="process" id="process_id" placeholder="" onChange={e => setProcess(e.target.value)} />
          <div>
            <label>Available date:</label>
          </div>
          <input type="text" value={availableDate} name="availableDate" id="availableDate_id" placeholder="" onChange={e => setAvailableDate(e.target.value)} />
          
          <div>
            <label>price:</label>
          </div>
          
          <input type="text" className="text-in-form" value={price} name="price" id="price_id" placeholder="" onChange={e => setPrice(e.target.value)} />
          <hr/>

          <div>
            <label>Images:</label>
          </div>
          <div >
          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <FileUpload initialImages={imagesUrls} onImagesChange={setImagesUrls} type="image" />
          </div>
          </div>
          <hr />
          <div>
            <label>Internal files:</label>
          </div>
          
          <div >
          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <InternalFileUpload initialImages={internalFilesUrls} onImagesChange={setInternalFilesUrls} type="internal" />
          </div>
          </div>
          <div>
          <hr color="blue" width="100%" />
          
            <label>External files(image/pdf only):</label>
          </div>
          
          <div >
          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <ExternalFileUpload initialImages={externalFilesUrls} onImagesChange={setExternalFilesUrls} type="" />
          </div>
          </div>
          {/* <input type="file" className="form-control-file image-upload" onChange={uploadImage} /> */}
          {/* <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
            {itemData.map(item => (
              <ImageListItem key={item.img}>
                <img srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`} src={`${item.img}?w=164&h=164&fit=crop&auto=format`} alt={item.title} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList> */}
          <div>
            <button type="submit" className="btn btn-primary" onClick={postAds}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddBroadAds
