import React, { useState, useEffect } from "react"
import { Button, Table, Form } from "react-bootstrap"
import * as XLSX from "xlsx"
import axios from "axios"
import AppContext from "../../AppContext"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"
import CompanyList from "../company/CompanyList"
import CompanyContactList from "../companyContact/CompanyContactList"

const ExcelImport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFeature,setIsFeature] = useState(false)
  const [hideFromFrontEnd,setHideFromFrontEnd] = useState(false)
  const [contacts1Options, setContacts1Options] = useState()
  const [contacts2Options, setContacts2Options] = useState()
  const [contacts3Options, setContacts3Options] = useState()
  const [contactsRealOwnerOptions, setContactsRealOwnerOptions] = useState()
  const [companyOptions, setCompanyOptions] = useState()
  const [companyList, setCompanyList] = useState()
  const [exclusive, setExclusive] = useState(false)

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait';
    } else {
      document.body.style.cursor = 'auto';
    }

    // Clean up the cursor style when the component is unmounted or loading ends
    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [isLoading]);
  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/company`, config)
    setCompanyList(result.data)
    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setCompanyOptions(category)
  }, [])


  //important
  const navigate = useNavigate()
  const params = useParams()

  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  //
  const [excelData, setExcelData] = useState([])
  const [mapping, setMapping] = useState({
    // mass_initial_make:"",
    // mass_initial_model:"",
    // mass_initial_category:"",
    description: "",
    makes: "",
    model: "",
    category: "",
    wafer_size: "",
    serial_number: "",
    process: "",
    toolId:"",
    toolStatus:"",
    vintage:"",
    configuration:"",
    price:"",
    country:"",
    note:"",
    state:"",
    city:"",
    available_date:"",


  })
  const [mappedData, setMappedData] = useState([])
  const [makesOptions, setMakesOptions] = useState([])
  const [modelOptions, setModelOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])

  const batchNumber = generateRandomString(20)
  function uniqueByName(data, key) {
    return [...new Map(data.map(x => [key(x), x])).values()]
  }
  useEffect(() => {
    // Fetch the options from the server when the component mounts
    const fetchOptions = async () => {
      // const makesRes = await axios.get('/api/makes'); // Assume API returns [{ value: 1, label: 'Make 1' }, ...]
      // const modelRes = await axios.get('/api/models');
      // const categoryRes = await axios.get('/api/categories');
      axios(`${AppContext.apiUrl}/api/makes`).then(res => {
        const category = res.data.map(cat => ({ value: cat.id, label: cat.name }))
        //console.log("category " + category)
        setMakesOptions(category)
      })
      //setMakesOptions(makesRes.data);
      // setModelOptions(modelRes.data);
      // setCategoryOptions(categoryRes.data);
      let result = await axios(`${AppContext.apiUrl}/api/category`)
    const categories = uniqueByName(result.data, it => it.name)
    // console.log("----------------" + JSON.stringify(uniqueByName(result.data, it => it.name)))

    const category = categories.map(cat => ({ value: cat.id, label: cat.name }))
    setCategoryOptions(category)
    }

    fetchOptions()
  }, [])

  const handleFileUpload = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      const binaryStr = event.target.result
      const workbook = XLSX.read(binaryStr, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(worksheet)
      setExcelData(jsonData)
    }

    console.log("ecel data Display:", excelData);

    reader.readAsBinaryString(file)
  }

  const handleMappingChange = e => {
    setMapping({
      ...mapping,
      [e.target.name]: e.target.value
    })
  }

  const handleDataEdit = async (index, field, value) => {
    const newData = [...mappedData]
    newData[index][field] = value
    setMappedData(newData)
    
      setMappedData(newData);
  // }
  }

  const handleMapAndDisplay = () => {
    console.log("Parsed Excel Data:", excelData);
    const data = excelData.map(row => ({
      // mass_initial_make:row[mapping.makes],
      // mass_initial_model:row[mapping.model],
      // mass_initial_category:row[mapping.category],
      description: row[mapping.description],
      makes: row[mapping.makes],
      model: row[mapping.model],
      category: row[mapping.category],
      wafer_size: row[mapping.wafer_size],
      serial_number: row[mapping.serial_number],
      process: row[mapping.process],
      toolId:row[mapping.toolId],
      toolStatus:row[mapping.toolStatus],
      vintage:row[mapping.vintage],
      configuration:row[mapping.configuration],
      price:row[mapping.price]?row[mapping.price]:0,
      country:row[mapping.country],
      note:row[mapping.note],
    state:row[mapping.state],
    city:row[mapping.city],
    available_date:[mapping.available_date],

    }))

    setMappedData(data)
    console.log(mappedData)
  }

  const handleIsFeatureChange = (e)=>{
    setIsFeature(e.target.value === "true")
  }
  
  const handleHideFromFrontChange = (e)=>{
    setHideFromFrontEnd(e.target.value === "true")
  }

  // referal company and contact
// refer1
const [selectedCompany1Value, setSelectedCompany1Value] = useState("")

const handleSelectCompany1Change = value => {
  setSelectedCompany1Value(value)
  console.log("company1 is ", value)
}

const [selectedCompany1ContactValue, setSelectedCompany1ContactValue] = useState("")

const handleSelectCompany1ContactChange = value => {
  setSelectedCompany1ContactValue(value)
  console.log("contact1 is ", value)
}

//refer2
const [selectedCompany2Value, setSelectedCompany2Value] = useState("")

const handleSelectCompany2Change = value => {
  setSelectedCompany2Value(value)
  console.log("company2 is ", value)
}

const [selectedCompany2ContactValue, setSelectedCompany2ContactValue] = useState("")

const handleSelectCompany2ContactChange = value => {
  setSelectedCompany2ContactValue(value)
  console.log("contact2 is ", value)
}

//refer3
const [selectedCompany3Value, setSelectedCompany3Value] = useState("")

const handleSelectCompany3Change = value => {
  setSelectedCompany3Value(value)
  console.log("company3 is ", value)
}

const [selectedCompany3ContactValue, setSelectedCompany3ContactValue] = useState("")

const handleSelectCompany3ContactChange = value => {
  setSelectedCompany3ContactValue(value)
  console.log("contact3 is ", value)
}

//real owner
const [selectedRealOwnerCompanyValue, setSelectedRealOwnerCompanyValue] = useState("")

const handleSelectRealOwnerCompanyChange = value => {
  setSelectedRealOwnerCompanyValue(value)
  console.log("realowner is ", value)
}

const [selectedRealOwnerCompanyContactValue, setSelectedRealOwnerCompanyContactValue] = useState("")

const handleSelectRealOwnerCompanyContactChange = value => {
  setSelectedRealOwnerCompanyContactValue(value)
  console.log("real owner contact is ", value)
}

useEffect(() => {
  if (selectedCompany1Value) {
    companyList.filter(company => {
      if (company.id === selectedCompany1Value.value) {
        const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
        setContacts1Options(contactList)
      }
    })
  }
}, [selectedCompany1Value])

useEffect(() => {
  if (selectedCompany2Value) {
    companyList.filter(company => {
      if (company.id === selectedCompany2Value.value) {
        const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
        setContacts2Options(contactList)
      }
    })
  }
}, [selectedCompany2Value])

useEffect(() => {
  if (selectedCompany3Value) {
    companyList.filter(company => {
      if (company.id === selectedCompany3Value.value) {
        const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
        setContacts3Options(contactList)
      }
    })
  }
}, [selectedCompany3Value])

useEffect(() => {
  if (selectedRealOwnerCompanyValue) {
    companyList.filter(company => {
      if (company.id === selectedRealOwnerCompanyValue.value) {
        const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
        setContactsRealOwnerOptions(contactList)
      }
    })
  }
}, [selectedRealOwnerCompanyValue])

// end of referal company
  const handleSubmit = async() => {

    //validate data
    setIsLoading(true)

    const apiPromises  = mappedData.map(async row => {
      const data = {
        title: row.makes+' '+row.model,
        // country: value.value,
        description: row.description,
        // image: imagesUrls.join("$"),
        // internalFiles: internalFilesUrls.join("$"),
        // externalFiles: externalFilesUrls.join("$"),
        // location: adsLocation,
        hideFromFrontEnd: 0,
        user: {
          id: id
        },
        category: null,
        company: {
          id: params.companyId ? params.companyId : 1
        },
        contact: {
          id: params.contactId ? params.contactId : 1
        },
        make: null,
        model: null,
        price:  0,
        status:  "ACTIVE",
        waferSize: row.wafer_size ?  row.wafer_size: "",
        
        serialNumber: row.serial_number,
        type: 0,
        feature:isFeature?1:0,
        batchListNumber:batchNumber,
        country:row.country,
        
        process:row.process,
        massInitialMake:row.makes,
        massInitialModel:row.model,
        massInitialCategory:row.category,
        // availableDate:availableDate,
        // referal1Company:selectedCompany1Value?selectedCompany1Value.value:null,
        // referal1Contact:selectedCompany1ContactValue?selectedCompany1ContactValue.value:null,
        // referal2Company:selectedCompany2Value?selectedCompany2Value.value:null,
        // referal2Contact:selectedCompany2ContactValue?selectedCompany2ContactValue.value:null,
        // referal3Company:selectedCompany3Value?selectedCompany3Value.value:null,
        // referal3Contact:selectedCompany3ContactValue?selectedCompany3ContactValue.value:null,
        // referalRealOwnerCompany:selectedRealOwnerCompanyValue?selectedRealOwnerCompanyValue.value:null,
        // referalRealOwnerContact:selectedRealOwnerCompanyContactValue?selectedRealOwnerCompanyContactValue.value:null,
        toolId:row.toolId,
        toolStatus:row.toolStatus,
        vintageString:row.vintage,
      configuration:row.configuration,
      price:row.price,
      note:row.note,
      state:row.state,
      city:row.city,
      available_date:row.available_date,
      hideFromFrontEnd:hideFromFrontEnd,
      referal1Company:selectedCompany1Value?selectedCompany1Value.value:null,
      referal1Contact:selectedCompany1ContactValue?selectedCompany1ContactValue.value:null,
      referal2Company:selectedCompany2Value?selectedCompany2Value.value:null,
      referal2Contact:selectedCompany2ContactValue?selectedCompany2ContactValue.value:null,
      referal3Company:selectedCompany3Value?selectedCompany3Value.value:null,
      referal3Contact:selectedCompany3ContactValue?selectedCompany3ContactValue.value:null,
      referalRealOwnerCompany:selectedRealOwnerCompanyValue?selectedRealOwnerCompanyValue.value:null,
      referalRealOwnerContact:selectedRealOwnerCompanyContactValue?selectedRealOwnerCompanyContactValue.value:null,
      feature:isFeature?1:0,
      exclusive:exclusive,

      }
  
      await axios
        .post(`${AppContext.apiUrl}/api/ads`, data, config)
        .then(response => {
          // console.log(response.data)
          
          
          //   setOpen(true)
          //   setSeverity("success")
          //   setMessage("Mass upload was successfull.")
          //   //navigate(`/editCard/${response.data.id}`)
          //   navigate(`/admin`)
          
          
        })
        .catch(error => {
          console.log("error happend: ", error)
          //navigate("/login")
          setOpen(true)
            setSeverity("error")
            setMessage("Something went wrong, please contact the admin for help")
          })
      // await axios.post("/ads", row)
    })

    await Promise.all(apiPromises);
    setOpen(true)
    setSeverity("success")
    setMessage("Mass upload was successfull.")
    setIsLoading(false)
    navigate(`/admin/batchList/${batchNumber}`)
  }


  // this will get the matched makes, but no model yet
//   useEffect(() => {
//     console.log('Mapped Data:', mappedData); // Log the mappedData before processing
//     const updatedData = mappedData.map((row) => {
//         console.log('Processing row:', row); // Log each row before processing
//         if (row.makes && makesOptions) {
//             console.log('Found a maker to map:', row.makes); // Log when a maker is found

//             // Check if row.makes is a string before applying trim
//             const rowMakes = typeof row.makes === 'string' ? row.makes.trim().toLowerCase() : null;

//             const matchingMake = makesOptions.find(option => {
//                 if (option.label === null || rowMakes === null) return false; // Return false instead of undefined
//                 return option.label.trim().toLowerCase() === rowMakes;
//             });

//             if (matchingMake) {
//                 console.log('Matching make found:', matchingMake); // Log the matching make
//                 return { ...row, makes: matchingMake.value };
//             }
//         }
//         return row;
//     });

//     if (JSON.stringify(mappedData) !== JSON.stringify(updatedData)) {
//         console.log('Updating Mapped Data'); // Log when updating mapped data
//         setMappedData(updatedData);
//     }
// }, [mappedData, makesOptions]);


function getValueByLabel(data, targetLabel) {
  const item = data.find(entry => entry.label === targetLabel);
  return item ? item.value : null;
}

function generateRandomString(length) {
  let result = '';
  const characters = '0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      <h2>Import Excel File</h2>
      <input type="file" onChange={handleFileUpload} />

      {excelData.length > 0 && (
        <div>
          <h3>Column Mapping</h3>
          <Form>
            {Object.keys(mapping).map(key => (
              (key!=="mass_initial_make"&&key!=="mass_initial_model"&&key!=="mass_initial_category")&&(
              <Form.Group controlId={`mapping-${key}`} key={key}>
                <Form.Label>{key}</Form.Label>
                <Form.Control as="select" name={key} onChange={handleMappingChange}>
                  <option value="">Select Excel Column</option>
                  {Object.keys(excelData[0]).map(column => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              )
            ))}
          </Form>
          <Form.Label>Is feature?</Form.Label>
                <Form.Control as="select"  name="is_feature" onChange={handleIsFeatureChange}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                  
                </Form.Control>
                <Form.Label>Hide from front?</Form.Label>
                <Form.Control as="select"  name="hide_from_front" onChange={handleHideFromFrontChange}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                  
                </Form.Control>
                <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 1 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany1Value} onSelectChange={handleSelectCompany1Change} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 1 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts1Options} selectedValue={selectedCompany1ContactValue} onSelectChange={handleSelectCompany1ContactChange} />
          </div>

          {/* /* referal 2 */ }
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 2 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany2Value} onSelectChange={handleSelectCompany2Change} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 2 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts2Options} selectedValue={selectedCompany2ContactValue} onSelectChange={handleSelectCompany2ContactChange} />
          </div>

{/* /* referal 3 */ }
<div>
            <label>
              <span style={{ color: "red" }}></span>Referal 3 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany3Value} onSelectChange={handleSelectCompany3Change} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 3 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts3Options} selectedValue={selectedCompany3ContactValue} onSelectChange={handleSelectCompany3ContactChange} />
          </div>

{/* /* real owner */ }
<div>
            <label>
              <span style={{ color: "red" }}></span>Real Owner Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedRealOwnerCompanyValue} onSelectChange={handleSelectRealOwnerCompanyChange} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Real Owner Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contactsRealOwnerOptions} selectedValue={selectedRealOwnerCompanyContactValue} onSelectChange={handleSelectRealOwnerCompanyContactChange} />
          </div>
          
          <div>
            <label>Exclusive listing?</label>
          </div>
          <div>
            <div>
              Yes
              <input type="radio" name="exclusive" value="true" onChange={e => setExclusive(true)} checked={exclusive === true} />{" "}
            </div>
            <div>
              No <input type="radio" name="exclusive" value="false" onChange={e => setExclusive(false)} checked={exclusive === false} />
            </div>
          </div>
          <Button variant="primary" onClick={handleMapAndDisplay}>
            Map and Display Data
          </Button>
        </div>
      )}

      {mappedData.length > 0 && (
        <div>
          <h3>Review and Edit Data</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                {Object.keys(mapping).map(field => (
                  <th key={field}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {mappedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(mapping).map(field => {
                    if(field ==="makes"){
                      return <td key={field}>{row.makes}</td>
                    }else if(field ==="model"){
                      return <td key={field}>{row.model}</td>
                    }else if(field ==="category"){
                      return <td key={field}>{row.category}</td>
                    }else if(field ==="description"){
                      return <td key={field}>{row.description}</td>
                    }else if(field ==="vintage"){
                      return <td key={field}>{row.vintage}</td>
                    }else if(field ==="toolId"){
                      return <td key={field}>{row.toolId}</td>
                    }else if(field ==="toolStatus"){
                      return <td key={field}>{row.toolStatus}</td>
                    }else if(field ==="wafer_size"){
                      return <td key={field}>{row.wafer_size}</td>
                    }else if(field ==="serial_number"){
                      return <td key={field}>{row.serial_number}</td>
                    }else if(field ==="configuration"){
                      return <td key={field}>{row.configuration}</td>
                    }else if(field ==="price"){
                      return <td key={field}>{row.price}</td>
                    }else if(field ==="country"){
                      return <td key={field}>{row.country}</td>
                    }else if(field ==="note"){
                      return <td key={field}>{row.note}</td>
                    }else if(field ==="state"){
                      return <td key={field}>{row.state}</td>
                    }else if(field ==="city"){
                      return <td key={field}>{row.city}</td>
                    }else if(field ==="available_date"){
                      return <td key={field}>{row.available_date}</td>
                    }

//                     else if (field === "makes") {
//                       return (
//                         <td key={field}>
//                           <Form.Control as="select" value={row.makes} onChange={e => handleDataEdit(rowIndex, 'makes', e.target.value)}>
//     <option value="">Select Make</option>
//     {makesOptions.map(option => (
//         <option key={option.value} value={option.value}>
//             {option.label}
//         </option>
//     ))}
// </Form.Control>

//                         </td>
//                       )
//                     } else if (field === "model") {
//                       return (
//                         <td key={field}>
//                           <Form.Control as="select" value={row[field]} onChange={e => handleDataEdit(rowIndex, field, e.target.value)}>
//                             <option value="">Select Model</option>
//                             {row.modelOptions &&
//                               row.modelOptions.map(option => (
//                                 <option key={option.value} value={option.value}>
//                                   {option.label}
//                                 </option>
//                               ))}
//                           </Form.Control>
//                         </td>
//                       )
//                     } else if (field === "category") {
//                       return (
//                         <td key={field}>
//                           <Form.Control as="select" value={row.category} onChange={e => handleDataEdit(rowIndex, field, e.target.value)}>
//                             <option value="">Select Category</option>
//                             {row.categoryOptions && row.categoryOptions.map(option => (
//                               <option key={option.value} value={option.value}>
//                                 {option.label}
//                               </option>
//                             ))}
//                           </Form.Control>
//                         </td>
//                       )
//                     } 
else 
{
                      return (
                        <td key={field}>
                          {row.field?row.field:""}
                          {/* <Form.Control type="text" value={row.field} onChange={e => handleDataEdit(rowIndex, field, e.target.value)} /> */}
                        </td>
                      )
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
          {!isLoading &&(
          <Button variant="success" onClick={handleSubmit}>
            Submit Data
          </Button>
          )}
          {isLoading &&(
            <p>Uploading, please wait</p>
          )}
        </div>
      )}
    </div>
  )
}

export default ExcelImport
