import React from "react";

export default function EmailPrompt  ({
  recipientName,
  equipmentModel,
  equipmentURL,
  yourName,
  yourPosition,
  yourContactInfo,
}) {
  // Dynamically generate the email prompt
  const prompt = `
Write a professional email in 100 words introducing a new ad listing. The email should include:
- The recipient's name: ${recipientName}
- The equipment model: ${equipmentModel}
- A URL to the listing: ${equipmentURL}
- A reference to the recipient's previous interest in the ${equipmentModel}.
- The sender's name: ${yourName}
- The sender's position: ${yourPosition}
- The sender's contact information: ${yourContactInfo}.

Make the tone polite, engaging, and concise. Use proper formatting for a professional email.
  `;

  return (
    <div>
      <h2>Email Prompt:</h2>
      <pre>{prompt}</pre>
    </div>
  );
};