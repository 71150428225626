import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import CompanyList from "../company/CompanyList"
import Makes from "../productMakes/Makes"
import Models from "../model/Models"
import CompanyContactList from "../companyContact/CompanyContactList"
import Select from "react-select"
import countryList from "react-select-country-list"
import AppContext from "../../AppContext"
import Category from "../category/Category"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import FileUpload from "../FileUpload"
import InternalFileUpload from "../InternalFileUpload"
import ExternalFileUpload from "../ExternalFileUpload"
import { decodeStringToNumber,encodeNumberTo10CharString } from "../../NumberCodec"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"

function EditBroadCard(props) {
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))
  const isAdmin = () => {
    return roles.name.includes("user");
  };
  const params = useParams() //params.adsId
  const [data, setData] = useState()
  const [adsTitle, setAdsTitle] = useState()
  const [country, setCountry] = useState()
  const [price, setPrice] = useState(0)
  const [adsLocation, setadsLocation] = useState()
  const [adsDescription, setadsDescription] = useState()
  const [adsImage, setadsImage] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [makesOptions, setMakesOptions] = useState()
  const [makesList, setMakesList] = useState()
  const [modelsOptions, setModelsOptions] = useState()
  const [category, setCategory] = useState()
  const [categoryName, setCategoryName] = useState()
  const [contactsOptions, setContactsOptions] = useState()
  const [companyOptions, setCompanyOptions] = useState()
  const [companyList, setCompanyList] = useState()
  const [waferSizeOptions, setWaferSizeOptions] = useState()
  const [selectedWaferSizeValue, setSelectedWaferSizeValue] = useState()
  const [configuration, setConfiguration] = useState()
  const [note, setNote] = useState()
  const [vintage, setVintage] = useState()
  const [serialNumber, setSerialNumber] = useState()
  const [hideFromFrontEnd, setHideFromFrontEnd] = useState()
  const [imagesUrls, setImagesUrls] = useState([])
  const [featuredItem, setFeaturedItem] = useState(false)

  const [internalFilesUrls, setInternalFilesUrls] = useState([])
  const [externalFilesUrls, setExternalFilesUrls] = useState([])

  const [state_, setState_] = useState()
  const [city, setCity] = useState()
  const [quantity, setQuantity] = useState()
  const [process, setProcess] = useState()
  const [availableDate, setAvailableDate] = useState()
  const [statusOptions, setStatusOptions] = useState()
  const [selectedStatusValue, setSelectedStatusValue] = useState()
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  const [adsType,setAdsType] = useState()
  //referal 
  const [contacts1Options, setContacts1Options] = useState()
  const [contacts2Options, setContacts2Options] = useState()
  const [contacts3Options, setContacts3Options] = useState()
  const [contactsRealOwnerOptions, setContactsRealOwnerOptions] = useState()
  const [initContact1Id,setInitContact1Id] = useState()
  const [initContact2Id,setInitContact2Id] = useState()
  const [initContact3Id,setInitContact3Id] = useState()
  const [initContact4Id,setInitContact4Id] = useState()  
  const [readOnly,setReadOnly] = useState(false)
  const [massInitialMake,setMassInitialMake] = useState()
  const [massInitialModel,setMassInitialModel] = useState()
  const [massInitialCategory,setMassInitialCategory] = useState()
  const [batchListNumber,setBatchListNumber] = useState()
  const [exclusive, setExclusive] = useState(false)


  function formatCurrency(e) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(e)
  }

  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  useEffect(async () => {
    const result = await axios.get(`${AppContext.apiUrl}/api/admin/ads/${params.adsId}`,config)
    setData(result.data)
    setAdsTitle(result.data.title)
    setadsLocation(result.data.location)
    setadsDescription(result.data.description)
    setImage(result.data.image)
    // setCategory(result.data.category)
    // setCategoryName(result.data.category.name)

    setSelectedMakeValue({ value: result.data.make?result.data.make.id:0, label: result.data.make?result.data.make.name:"Unknown" })
    setSelectedModelValue({ value: result.data.model?result.data.model.id:0, label: result.data.model?result.data.model.name:"Unknown" })
    setSelectedCompanyValue({ value: result.data.company?result.data.company.id:0, label: result.data.company?result.data.company.name:"" })
    setSelectedCompanyContactValue({ value: result.data.contact?result.data.contact.id:0, label: result.data.contact?result.data.contact.firstName:"" })
    setSelectedWaferSizeValue({ value: result.data.waferSize, label: result.data.waferSize })
    setSelectedCategoryValue({ value: result.data.category?result.data.category.id:0, label: result.data.category?result.data.category.name:"Unknown" })
    setConfiguration(result.data.configuration)
    setNote(result.data.note)
    setVintage(result.data.vintage)
    setSerialNumber(result.data.serialNumber)
    //find the country with code
    setValue(options.find(e => e.value === result.data.country))
    setPrice(formatCurrency(result.data.price ? result.data.price : 0))
    setHideFromFrontEnd(result.data.hideFromFrontEnd)
    setImagesUrls(result.data.image ? result.data.image.split("$") : null)
    setFeaturedItem(result.data.feature===0?false:true)
    setInternalFilesUrls(result.data.internalFiles ? result.data.internalFiles.split("$") : null)
    setExternalFilesUrls(result.data.externalFiles ? result.data.externalFiles.split("$") : null)
setAdsType(result.data.type)

    setState_(result.data.state)
    setCity(result.data.city)
    setProcess(result.data.process)
    setQuantity(result.data.quantity?result.data.quantity:"")
    setAvailableDate(result.data.availableDate)
    setSelectedStatusValue({ value: result.data.status, label: result.data.status })

    setMassInitialMake(result.data.massInitialMake)
    setMassInitialModel(result.data.massInitialModel)
    setMassInitialCategory(result.data.massInitialCategory)
    setBatchListNumber(result.data.batchListNumber)
    setSelectedCompany1Value(companyOptions?companyOptions.find(
      (option) => option.value === result.data.referal1Company // Ensure both are strings for comparison
  ):null)

  setInitContact1Id(result.data.referal1Contact)
  setInitContact2Id(result.data.referal2Contact)
  setInitContact3Id(result.data.referal3Contact)
  setInitContact4Id(result.data.referalRealOwnerContact)
  setReadOnly(!isAdmin()&& id!=result.data.user_id)
  setExclusive(result.data.exclusive)  
  }, [])

  useEffect(async () => {
    const result = await axios.get(`${AppContext.apiUrl}/api/admin/ads/${params.adsId}`,config)
    setData(result.data)
    setAdsTitle(result.data.title)
    setadsLocation(result.data.location)
    setadsDescription(result.data.description)
    setImage(result.data.image)
    // setCategory(result.data.category)
    // setCategoryName(result.data.category.name)

    setSelectedMakeValue({ value: result.data.make?result.data.make.id:0, label: result.data.make?result.data.make.name:"Unknown" })
    setSelectedModelValue({ value: result.data.model?result.data.model.id:0, label: result.data.model?result.data.model.name:"Unknown" })
    setSelectedCompanyValue({ value: result.data.company?result.data.company.id:0, label: result.data.company?result.data.company.name:"" })
    setSelectedCompanyContactValue({ value: result.data.contact?result.data.contact.id:0, label: result.data.contact?result.data.contact.firstName:"" })
    setSelectedWaferSizeValue({ value: result.data.waferSize, label: result.data.waferSize })
    setSelectedCategoryValue({ value: result.data.category?result.data.category.id:0, label: result.data.category?result.data.category.name:"Unknown" })
    setConfiguration(result.data.configuration)
    setNote(result.data.note)
    setVintage(result.data.vintage)
    setSerialNumber(result.data.serialNumber)
    //find the country with code
    setValue(options.find(e => e.value === result.data.country))
    setPrice(formatCurrency(result.data.price ? result.data.price : 0))
    setHideFromFrontEnd(result.data.hideFromFrontEnd)
    setImagesUrls(result.data.image ? result.data.image.split("$") : null)
    setFeaturedItem(result.data.feature===0?false:true)
    setInternalFilesUrls(result.data.internalFiles ? result.data.internalFiles.split("$") : null)
    setExternalFilesUrls(result.data.externalFiles ? result.data.externalFiles.split("$") : null)

    setState_(result.data.state)
    setCity(result.data.city)
    setProcess(result.data.process)
    setQuantity(result.data.quantity?result.data.quantity:"")
    setAvailableDate(result.data.availableDate)
    setSelectedStatusValue({ value: result.data.status, label: result.data.status })

    setMassInitialMake(result.data.massInitialMake)
    setMassInitialModel(result.data.massInitialModel)
    setMassInitialCategory(result.data.massInitialCategory)
    setBatchListNumber(result.data.batchListNumber)

    setSelectedCompany1Value(companyOptions?companyOptions.find(
      (option) => option.value === result.data.referal1Company // Ensure both are strings for comparison
  ):null)
  setSelectedCompany2Value(companyOptions?companyOptions.find(
    (option) => option.value === result.data.referal2Company // Ensure both are strings for comparison
):null)
setSelectedCompany3Value(companyOptions?companyOptions.find(
  (option) => option.value === result.data.referal3Company // Ensure both are strings for comparison
):null)
setSelectedRealOwnerCompanyValue(companyOptions?companyOptions.find(
  (option) => option.value === result.data.referalRealOwnerCompany // Ensure both are strings for comparison
):null)
    setReadOnly(!isAdmin()&& id!=result.data.user_id)
    setExclusive(result.data.exclusive)
  }, [companyOptions])

  const changeHandler = value => {
    setValue(value)
  }
  const token = JSON.parse(localStorage.getItem("token"))

  let file = false
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("")

  const handleSelectCategoryChange = value => {
    setSelectedCategoryValue(value)
    console.log("category is ", value)
  }

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  

  const title = e => {
    setAdsTitle(e.target.value)
  }

  const location = e => {
    setadsLocation(e.target.value)
  }

  const description = e => {
    setadsDescription(e.target.value)
  }

  const uploadImage = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append("file", files[0])
    data.append("upload_preset", "adsimage")
    setLoading(true)

    const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data).then(res => {
      file = true
      setImage(res.data.secure_url)
      setLoading(false)
    })
  }
  useEffect(() => {
    console.log("-----imagesUrls when editing ads---------------->>>")
    console.log(imagesUrls)
    //update image field
    //setImage(imagesUrls.join("$"))
  }, [imagesUrls])

  const postAds = e => {
    e.preventDefault()
    const data = {
      title: adsTitle,
      country: value ? value.value : "",
      description: adsDescription,
      image: imagesUrls?imagesUrls.join("$"):"",
      internalFiles: internalFilesUrls?internalFilesUrls.join("$"):"",
      externalFiles: externalFilesUrls?externalFilesUrls.join("$"):"",
      location: adsLocation,
      user: {
        id: id
      },

      company: {
        id: selectedCompanyValue.value
      },
      contact: {
        id: selectedCompanyContactValue.value
      },
      make: selectedMakeValue?{
        id: selectedMakeValue.value
      }:null,
      model:selectedModelValue? {
        id: selectedModelValue.value
      }:null,
      category: selectedCategoryValue?{
        id: selectedCategoryValue ? selectedCategoryValue.value : 0
      }:null,
      price: price.replace("$", "").replaceAll(",", ""),
      status: selectedStatusValue.value,
      waferSize: selectedWaferSizeValue.value,
      configuration: configuration,
      note: note,
      vintage: vintage,
      serialNumber: serialNumber,
      hideFromFrontEnd: hideFromFrontEnd,
      feature:featuredItem?1:0,
      state:state_,
      city:city,
      quantity:quantity,
      process:process,
      availableDate:availableDate,
      referal1Company:selectedCompany1Value?selectedCompany1Value.value:null,
      referal1Contact:selectedCompany1ContactValue?selectedCompany1ContactValue.value:null,
      referal2Company:selectedCompany2Value?selectedCompany2Value.value:null,
      referal2Contact:selectedCompany2ContactValue?selectedCompany2ContactValue.value:null,
      referal3Company:selectedCompany3Value?selectedCompany3Value.value:null,
      referal3Contact:selectedCompany3ContactValue?selectedCompany3ContactValue.value:null,
      referalRealOwnerCompany:selectedRealOwnerCompanyValue?selectedRealOwnerCompanyValue.value:null,
      referalRealOwnerContact:selectedRealOwnerCompanyContactValue?selectedRealOwnerCompanyContactValue.value:null,
      exclusive:exclusive,

    }

    axios
      .put(`${AppContext.apiUrl}/api/ads/${params.adsId}`, data, config)
      .then(response => {
        console.log(response.data)
        setOpen(true)
        setSeverity("success")
        setMessage("Listing saved successfully.")

      })
      .catch(error => {
        console.log(error)
        setOpen(true)
        setMessage("Something went wrong, please contact the admin for help")
        setSeverity("error")
      })
  }
  const stateValue = { value: "Ion Implanters", label: "Ion Implanters" }

  const [selectedMakeValue, setSelectedMakeValue] = useState("")

  const handleSelectMakeChange = value => {
    setSelectedMakeValue(value)
    console.log("make is ", value)
  }

  const [selectedModelValue, setSelectedModelValue] = useState("")

  const handleSelectModelChange = value => {
    setSelectedModelValue(value)
    console.log("model is ", value)
  }

  const [selectedCompanyValue, setSelectedCompanyValue] = useState("")

  const handleSelectCompanyChange = value => {
    setSelectedCompanyValue(value)
    console.log("company is ", value)
  }

  const [selectedCompanyContactValue, setSelectedCompanyContactValue] = useState("")

  const handleSelectCompanyContactChange = value => {
    setSelectedCompanyContactValue(value)
    console.log("contact is ", value)
  }

  // refer1
  const [selectedCompany1Value, setSelectedCompany1Value] = useState("")

  const handleSelectCompany1Change = value => {
    setSelectedCompany1Value(value)
    console.log("company1 is ", value)
  }

  const [selectedCompany1ContactValue, setSelectedCompany1ContactValue] = useState("")

  const handleSelectCompany1ContactChange = value => {
    setSelectedCompany1ContactValue(value)
    console.log("contact1 is ", value)
  }

  //refer2
  const [selectedCompany2Value, setSelectedCompany2Value] = useState("")

  const handleSelectCompany2Change = value => {
    setSelectedCompany2Value(value)
    console.log("company2 is ", value)
  }

  const [selectedCompany2ContactValue, setSelectedCompany2ContactValue] = useState("")

  const handleSelectCompany2ContactChange = value => {
    setSelectedCompany2ContactValue(value)
    console.log("contact2 is ", value)
  }

  //refer3
  const [selectedCompany3Value, setSelectedCompany3Value] = useState("")

  const handleSelectCompany3Change = value => {
    setSelectedCompany3Value(value)
    console.log("company3 is ", value)
  }

  const [selectedCompany3ContactValue, setSelectedCompany3ContactValue] = useState("")

  const handleSelectCompany3ContactChange = value => {
    setSelectedCompany3ContactValue(value)
    console.log("contact3 is ", value)
  }

  //real owner
  const [selectedRealOwnerCompanyValue, setSelectedRealOwnerCompanyValue] = useState("")

  const handleSelectRealOwnerCompanyChange = value => {
    setSelectedRealOwnerCompanyValue(value)
    console.log("realowner is ", value)
  }

  const [selectedRealOwnerCompanyContactValue, setSelectedRealOwnerCompanyContactValue] = useState("")

  const handleSelectRealOwnerCompanyContactChange = value => {
    setSelectedRealOwnerCompanyContactValue(value)
    console.log("real owner contact is ", value)
  }

  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/makes`)

    setMakesList(result.data)

    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setMakesOptions(category)

    //set warfsize
    setWaferSizeOptions([
      { value: '4"', label: '4"' },
      { value: '5"', label: '5"' },
      { value: '6"', label: '6"' },
      { value: '8"', label: '8"' },
      { value: '12"', label: '12"' }
    ])
    //set status
    setStatusOptions([
      { value: 'ACTIVE', label: 'ACTIVE' },
      { value: 'PENDING', label: 'PENDING' },
      { value: 'SOLD', label: 'SOLD' }
    ])
  }, [])

//   useEffect(async () => {
//     if (selectedMakeValue) {
//       //need to make call by makes id from model and set model options
//       let result = await axios(`${AppContext.apiUrl}/api/models/getModelByMakesId?id=${selectedMakeValue.value}`).then(
//         res=>{
            
//               const modelsList = res.data.map(cat => ({ value: cat[0], label: cat[1] }))
//                // Create a Set to track unique labels
// const uniqueLabels = new Set();
// const filteredModelsList = modelsList.filter(model => {
//   if (uniqueLabels.has(model.label)) {
//     return false; // Skip if the label is already in the Set
//   } else {
//     uniqueLabels.add(model.label); // Add new label to the Set
//     return true; // Include this model in the filtered list
//   }
// });
//               setModelsOptions(filteredModelsList)
            
          
//         }
//       )

      
//     }
//   }, [selectedMakeValue])

  // useEffect(async () => {
  //   if (selectedModelValue && selectedModelValue.value !== 0 && selectedMakeValue.label!=="ALL MAKES") {
  //     try{
  //     let result = await axios(`${AppContext.apiUrl}/api/category/byMakeModel/${selectedMakeValue.value}/${selectedModelValue.value}`)

  //     //const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
  //     //console.log("category " + category)
  //     setSelectedCategoryValue({ value: result.data.id, label: result.data.name })
  //   }catch(error){
  //     console.log("error in get category by model and make")
  //   }
  //   }
  // }, [selectedModelValue])

  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/company`,config)
    setCompanyList(result.data)
    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setCompanyOptions(category)
  }, [])



  useEffect(() => {
    if (selectedCompanyValue & companyList) {
      companyList.filter(company => {
        if (company.id === selectedCompanyValue.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContactsOptions(contactList)
        }
      })
    }
  }, [selectedCompanyValue])

  const goToPublicUrl= ()=>{
    window.open(AppContext.frontUrl+"/for-sale/"+params.adsId,'_blank')
  }

  const goToBatchList= ()=>{
    window.open("/admin/batchList/"+batchListNumber,'_blank')
  }

  const goToCompanyUrl= (id)=>{
    window.open(AppContext.adminUrl+"company/"+id,'_blank')
  }

  const goToContactUrl= (id)=>{
    window.open(AppContext.adminUrl+"contact/"+encodeNumberTo10CharString(id),'_blank')
  }
  useEffect(() => {
    if (selectedCompany1Value && companyList) {
      companyList.filter(company => {
        if (company.id === selectedCompany1Value.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContacts1Options(contactList)
          setSelectedCompany1ContactValue(contactList?contactList.find(option=>option.value===initContact1Id):null)
        }
      })
    }
  }, [selectedCompany1Value])

  useEffect(() => {
    if (selectedCompany2Value && companyList) {
      companyList.filter(company => {
        if (company.id === selectedCompany2Value.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContacts2Options(contactList)
          setSelectedCompany2ContactValue(contactList?contactList.find(option=>option.value===initContact2Id):null)
        }
      })
    }
  }, [selectedCompany2Value])

  useEffect(() => {
    if (selectedCompany3Value &&companyList) {
      companyList.filter(company => {
        if (company.id === selectedCompany3Value.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContacts3Options(contactList)
          setSelectedCompany3ContactValue(contactList?contactList.find(option=>option.value===initContact3Id):null)
        }
      })
    }
  }, [selectedCompany3Value])

  useEffect(() => {
    if (selectedRealOwnerCompanyValue && companyList) {
      companyList.filter(company => {
        if (company.id === selectedRealOwnerCompanyValue.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.firstName +" "+cat.lastName }))
          setContactsRealOwnerOptions(contactList)
          setSelectedRealOwnerCompanyContactValue(contactList?contactList.find(option=>option.value===initContact4Id):null)
        }
      })
    }
  }, [selectedRealOwnerCompanyValue])

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      <Navbar />
      {data && (
        <form>
          <div className="ads-from">
            <div><label>{batchListNumber &&(
              <a onClick={goToBatchList} style={{textDecoration:"underLine",cursor:"pointer"}} ><b>Batch/Mass upload listing number:{batchListNumber}</b></a>
                
              )}</label></div>
            <div>
              <label>Ads title</label> 
              
              {adsType===0 && ( <a onClick={goToPublicUrl} style={{textDecoration:"underLine",cursor:"pointer"}} >Go to public url</a>)}
            </div>
            <input type="text" defaultValue={adsTitle} autoFocus onChange={title} name="title" id="title_id" readOnly={readOnly}/>
            <div>
            <label>Status:</label>
          </div>
          <Select options={statusOptions} value={selectedStatusValue} onChange={e => setSelectedStatusValue(e)} isDisabled={readOnly}/>

          
            <div>
              <label>Hide from front end?</label>
            </div>
            <div>
              <div>
                Yes <input type="radio" name="hideFromFrontEnd" value="true" onChange={e => setHideFromFrontEnd(true)} checked={hideFromFrontEnd === true} disabled={readOnly}/>
              </div>
              <div>
                No <input type="radio" name="hideFromFrontEnd" value="false" onChange={e => setHideFromFrontEnd(false)} checked={hideFromFrontEnd === false} disabled={readOnly}/>
              </div>
            </div>
            <div>
            <label>Featured Item? will show in Featured list if Yes is selected</label>
          </div>
          <div>
            <div>
              Yes
              <input type="radio" name="feature" value="true" onChange={e => setFeaturedItem(true)} checked={featuredItem === true} disabled={readOnly}/>{" "}
            </div>
            <div>
              No <input type="radio" name="feature" value="false" onChange={e => setFeaturedItem(false)} checked={featuredItem === false} disabled={readOnly}/>
            </div>
          </div>

          <div>
            <label>Exclusive listing?</label>
          </div>
          <div>
            <div>
              Yes
              <input type="radio" name="exclusive" value="true" onChange={e => setExclusive(true)} checked={exclusive === true} />{" "}
            </div>
            <div>
              No <input type="radio" name="exclusive" value="false" onChange={e => setExclusive(false)} checked={exclusive === false} />
            </div>
          </div>
          <div><label>mass_initial_make: {massInitialMake}, mass_initial_model: {massInitialModel}, mass_initial_category: {massInitialCategory}</label></div>
            <div>
              <label><span style={{ color: "red" }}>*</span>Makes:</label>
            </div>
            <div>
              <Makes options={makesOptions} selectedValue={selectedMakeValue} onSelectChange={handleSelectMakeChange} readOnly={readOnly}/>
            </div>
            <div>
              <label><span style={{ color: "red" }}>*</span>Model:</label>
            </div>
            {/* stateValue={{ value: "PI 9500", label: "PI 9500" }}  */}
            <div>
              <Models options={modelsOptions} selectedValue={selectedModelValue} onSelectChange={handleSelectModelChange} readOnly={readOnly}/>
            </div>
            <div>
              <label><span style={{ color: "red" }}>*</span>Category:</label>
            </div>
            <Category selectedValue={selectedCategoryValue} onSelectChange={handleSelectCategoryChange} readOnly={readOnly}/>
            <div>
              <label>Description</label>
            </div>
            <textarea rows="4" value={adsDescription} cols="50" placeholder="" name="descritpion" id="des_id" onChange={description} readOnly={readOnly}/>
            {/* <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              Category
            </button>
            <ul className="dropdown-menu">
              <button className="dropdown-item" type="button" onClick={categoryRealEstate}>
                backend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryCar}>
                frontend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryDevices}>
                devices
              </button>
            </ul>
            <h5 id="categoryTitle"></h5>
          </div> */}
            <div>
              <label><span style={{ color: "red" }}>*</span>Company:</label>
            </div>
            <div>
              <CompanyList options={companyOptions} selectedValue={selectedCompanyValue} onSelectChange={handleSelectCompanyChange} readOnly={readOnly}/>
             
            </div>
            <div>
              <label><span style={{ color: "red" }}>*</span>Company contact person:</label>
            </div>
            <div>
              {!readOnly &&(
              <CompanyContactList options={contactsOptions} selectedValue={selectedCompanyContactValue} onSelectChange={handleSelectCompanyContactChange} />
              )}
            </div>

{/* /* referal 1 */ }
<div>
            <label>
              <span style={{ color: "red" }}></span>Referal 1 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany1Value} onSelectChange={handleSelectCompany1Change} readOnly={readOnly}/>
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 1 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts1Options} selectedValue={selectedCompany1ContactValue} onSelectChange={handleSelectCompany1ContactChange} readOnly={readOnly}/>
          </div>

          {/* /* referal 2 */ }
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 2 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany2Value} onSelectChange={handleSelectCompany2Change} readOnly={readOnly}/>
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 2 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts2Options} selectedValue={selectedCompany2ContactValue} onSelectChange={handleSelectCompany2ContactChange} readOnly={readOnly}/>
          </div>

{/* /* referal 3 */ }
<div>
            <label>
              <span style={{ color: "red" }}></span>Referal 3 Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompany3Value} onSelectChange={handleSelectCompany3Change} readOnly={readOnly}/>
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Referal 3 Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contacts3Options} selectedValue={selectedCompany3ContactValue} onSelectChange={handleSelectCompany3ContactChange} readOnly={readOnly}/>
          </div>

{/* /* real owner */ }
<div>
            <label>
              <span style={{ color: "red" }}></span>Real Owner Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedRealOwnerCompanyValue} onSelectChange={handleSelectRealOwnerCompanyChange} readOnly={readOnly}/>
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}></span>Real Owner Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contactsRealOwnerOptions} selectedValue={selectedRealOwnerCompanyContactValue} onSelectChange={handleSelectRealOwnerCompanyContactChange} readOnly={readOnly}/>
          </div>


            <div>
              <label>Wafer Size:</label>
            </div>
            <Select options={waferSizeOptions} value={selectedWaferSizeValue} onChange={e => setSelectedWaferSizeValue(e)} isDisabled={readOnly}/>
            <div>
              <label>Configuration</label>
            </div>
            <input type="text" value={configuration} name="configuration" id="configuration_id" placeholder="" onChange={e => setConfiguration(e.target.value)} readOnly={readOnly}/>
            <div>
              <label>note</label>
            </div>
            <input type="text" value={note} name="note" id="note_id" placeholder="" onChange={e => setNote(e.target.value)} readOnly={readOnly}/>
            <div>
              <label>vintage</label>
            </div>
            <input type="text" value={vintage} name="vintage" id="vintage_id" placeholder="Put only numbers" onChange={e => setVintage(e.target.value)} readOnly={readOnly}/>
            <div>
              <label>serial number</label>
            </div>
            <input type="text" value={serialNumber} name="serialNumber" id="serialNumber_id" placeholder="" onChange={e => setSerialNumber(e.target.value)} readOnly={readOnly}/>

            <div>
              <label>country</label>
            </div>
            <Select options={options} value={value} onChange={changeHandler} isDisabled={readOnly}/>
            <div>
            <label>State:</label>
          </div>
          <input type="text" value={state_} name="state" id="state_id" placeholder="" onChange={e => setState_(e.target.value)} readOnly={readOnly}/>
          <div>
            <label>City</label>
          </div>
          <input type="text" value={city} name="city" id="city_id" placeholder="" onChange={e => setCity(e.target.value)} readOnly={readOnly}/>
          <div>
            <label>Quantity:</label>
          </div>
          <input type="text" value={quantity} name="quantity" id="quantity_id" placeholder="" onChange={e => setQuantity(e.target.value)} readOnly={readOnly}/>
          <div>
            <label>Process:</label>
          </div>
          <input type="text" value={process} name="process" id="process_id" placeholder="" onChange={e => setProcess(e.target.value)} readOnly={readOnly}/>
          <div>
            <label>Available date:</label>
          </div>
          <input type="text" value={availableDate} name="availableDate" id="availableDate_id" placeholder="" onChange={e => setAvailableDate(e.target.value)} readOnly={readOnly}/>
          
            <div>
              <label>price:</label>
            </div>
            <input type="text" value={price} name="price" id="price_id" placeholder="" onChange={e => setPrice(e.target.value)} readOnly={readOnly}/>

            <div>
            <label>Images:</label>
          </div>
          <div >
          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <FileUpload initialImages={imagesUrls} onImagesChange={setImagesUrls} type="image" />
          </div>
          </div>
          <hr />
          <div>
            <label>Internal files:</label>
          </div>
          
          <div >
          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <InternalFileUpload initialImages={internalFilesUrls} onImagesChange={setInternalFilesUrls} type="internal" />
          </div>
          </div>
          <div>
          <hr color="blue" width="100%" />
          
            <label>External files(image/pdf only):</label>
          </div>
          
          <div >
          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <ExternalFileUpload initialImages={externalFilesUrls} onImagesChange={setExternalFilesUrls} type="" />
          </div>
          </div>
          {!readOnly &&(
            <button type="submit" className="btn btn-primary" onClick={postAds}>
              Submit
            </button>
            )}
          </div>
        </form>
      )}
    </div>
  )
}

export default EditBroadCard
